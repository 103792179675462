import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import axios from 'axios';
import { Tenant } from '../models/Tenant/TenantDTO';
import { User } from '../models/Tenant/UserDTO';
import apiToken from '../services/apiToken';




interface SignInCredentials {
  email: string;
  password: string;
}
interface SignInQrCodeCredentials {
  qrcode: string;
}
interface SignInTenantCredentials {
  domain: string;
  idbranch: number;
  code?: string;
  idtotem?: number;
}
interface AuthState {
  token: string;
  user: User;
}
interface AuthTenantState {
  tenant: Tenant;
}
interface AuthContextData {
  user: User;
  tenant: Tenant;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInQrCode(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User, token: string | null): void;
  updateTenant(tenant: Tenant): void;
  signInTenant(credentials: SignInTenantCredentials): Promise<void>;
}


const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

const AuthProvider= ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem('@HubfitTotem:token');
    const user = sessionStorage.getItem('@HubfitTotem:user');
    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`
      return { token, user: JSON.parse(user) };
    }
    return {} as AuthState;
  });
  const [dataTenant, setDataTenant] = useState<AuthTenantState>(() => {
    const tentant = localStorage.getItem('@HubfitTotem:tenant');
    if (tentant) {
      // api.defaults.headers.authorization = `Bearer ${token}`
      return { tenant: JSON.parse(tentant) };
    }
    return {} as AuthTenantState;
  });
  const signInQrCode = useCallback(async ( qrcode) => {

    const tenantString = localStorage.getItem('@HubfitTotem:tenant');
    if (tenantString) {
      const tenant = JSON.parse(tenantString);
      const response = await api.get('login/qrcode', { params:{
        qrcode: qrcode,
        idTenant:tenant.idTenant
      }});
      const { token } = response.data;
      sessionStorage.setItem('@HubfitTotem:token', token);

      api.defaults.headers.authorization = `Bearer ${token}`
      const responsePessoa = await api.get("users");
      sessionStorage.setItem('@HubfitTotem:user', JSON.stringify(responsePessoa.data));
      console.log(responsePessoa.data);

      setData({ token: token, user: responsePessoa.data });
    }
  }, []);
  
  const signIn = useCallback(async ({ email, password }) => {

    const tenantString = localStorage.getItem('@HubfitTotem:tenant');
    if (tenantString) {
      const tenant = JSON.parse(tenantString);
      const response = await apiToken.post('token', "grant_type=password&username=" + email + "&password=" + password + "&idTenant=" + tenant.idTenant + "&idBranch=" + tenant.idBranch);
      console.log(response.data);
      const { access_token, expires_in } = response.data;
      sessionStorage.setItem('@HubfitTotem:token', access_token);

      api.defaults.headers.authorization = `Bearer ${access_token}`
      const responsePessoa = await api.get("users");
      sessionStorage.setItem('@HubfitTotem:user', JSON.stringify(responsePessoa.data));
      console.log(responsePessoa.data);

      setData({ token: access_token, user: responsePessoa.data });
    }
  }, []);

  const signInTenant = useCallback(async ({ domain, idbranch, code, idtotem }) => {


    const response = await api.get('tenant/totem', {
      params: {
        domain,
        idbranch,
        code,
        idtotem
      }
    }).catch(error => {
      console.log()
      if(!error.response){
        throw new Error("Não foi possível conectar ao servidor.");
      }else if(error.response.status == "404"){
        throw new Error("Informações não encontradas.");
      }
      throw new Error(error.response.data.Message);
    });

    console.log(response.data);
 // response.data.virtual_key = true;  //APAGAR
    localStorage.setItem('@HubfitTotem:tenant', JSON.stringify(response.data));
    setDataTenant({ tenant: response.data });
    const userLocal = sessionStorage.getItem('@HubfitTotem:user');
    console.log(userLocal)
    console.log(response.data)
    if (userLocal) {
      const userObj: User = JSON.parse(userLocal);
      const tenantObj: Tenant = response.data;
      if (userObj.idTenant != tenantObj.idTenant) {
        console.log('removeu')
        await signOut();
      }
    }


  }, []);
  // const updateInsertUserToken = useCallback((user: User, token: string) => {
  //     console.log(user,token)
  //     setData({
  //       token,
  //       user,
  //     })
  //     sessionStorage.setItem('@HubfitTotem:token', token);
  //     sessionStorage.setItem('@HubfitTotem:user', JSON.stringify(user));

  //   api.defaults.headers.authorization = `Bearer ${token}`
  // },[setData, sessionStorage,api])

  const updateUser = useCallback((user: User, token: string) => {
    sessionStorage.setItem('@HubfitTotem:user', JSON.stringify(user));
    console.log(user, token)
    if (token) {
      sessionStorage.setItem('@HubfitTotem:token', token);
      setData({
        token: token,
        user,
      })
      api.defaults.headers.authorization = `Bearer ${token}`
    } else {
      setData({
        token: data.token,
        user,
      })
      api.defaults.headers.authorization = `Bearer ${data.token}`
    }

  }, [setData, data.token])

  const updateTenant = useCallback((tenant: Tenant) => {
    localStorage.setItem('@HubfitTotem:tenant', JSON.stringify(tenant));
      setDataTenant({
        tenant
      })
  }, [setDataTenant])

  const signOut = useCallback(() => {
    sessionStorage.removeItem('@HubfitTotem:token');
    sessionStorage.removeItem('@HubfitTotem:user');
    setData({} as AuthState);
  }, []);
  return (
    <AuthContext.Provider value={{ user: data.user, tenant: dataTenant.tenant, signIn, signOut, updateUser, signInTenant,updateTenant, signInQrCode }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be uset within and AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };



import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import Cards from 'react-credit-cards';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import 'react-credit-cards/es/styles-compiled.css';
import Recaptcha from 'react-recaptcha';
import ReactHtmlParser from 'react-html-parser';

import { Container, Content, AnimationContainer, Background } from './styles';
import { Input, Button, makeStyles, Theme, CircularProgress, Grid, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Typography, Paper, InputLabel, FormHelperText, Checkbox, Divider, TextField, Dialog, AppBar, Toolbar, IconButton, DialogContent, DialogContentText, MenuItem, Select, useTheme, Drawer } from '@material-ui/core';
import InputMask from "react-input-mask";
import { green, blue, grey } from '@material-ui/core/colors';
import { useAuth } from '../../../hooks/auth';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import Card from '../../../components/Card/Card';
import NumberFormat from 'react-number-format';
import { GridFooterCard } from '../../Plans/List/styles';
import { IPaymentSaleDTO, FormPaymentEnum, CreditCardDTO } from '../../../models/Payments/IPaymentSaleDTO';
import { ArrowBackIos, CheckCircle, Close as CloseIcon, CreditCard } from '@material-ui/icons';
import Header from '../../../components/Header';
import { IPlanItem, PayConfig } from '../../../models/Plans/IPlanDTO';
import { User, TypeUser } from '../../../models/Tenant/UserDTO';
import CardFooter from '../../../components/Card/CardFooter';
import DialogPlanDetails from '../../../components/DialogPlanDetails/Index';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/creditCard.json';
import Keyboard from "react-simple-keyboard";
// import { CountdownCircleTimer } from 'react-countdown-circle-timer';

interface IParcelaItem {
  value: number;
  text: number
}

interface IPaymentPending {
  Id: number;
  CobrarJuros: boolean;
  MaxParcelas: number;
  Identificador: string;
  ValorMulta: number;
  ValorOriginal: number;
  ValorTotal: number;
  payConfig: PayConfig;
}
const useStyles = makeStyles((theme: Theme) => ({

  cardPadding: {
    padding: '16px',
  },
  titleCard: {
    marginTop: '20px',
  },
  wrapper: {

    margin: '60px 0 0',
    width: '100%',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgressForm: {
   
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
 
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  SpanCard: {
    color: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',

  },
  IconInfo: {
    color: blue[500],
  },
  animationTab: {
    height: '100%',
  },
  Paper: {
    padding: '22px',
    marginBottom: '26px',
  },

  valuePlan: {
    marginTop: '2px !important',

  },
  IconCheck: {
    fontSize: '3.5875rem',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttonClose: {
    marginRight: 0,
  },
  ControlTerms: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

  },
  labelControlTerms: {
    marginRight: '6px',
  },
  buttonContract: {
    padding: '3px 1px',
    marginTop: '2px',
    color: theme.palette.primary.main,
  },
  paperContract: {
    padding: '40px',
  },
  backgroundContract: {
    backgroundColor: theme.palette.grey[200],
  }
  , margin: {
    margin: '8px 0',
    padding: '23px 55px',
    '& span': {
      display: 'flex !important',
      flexDirection: 'column !important',
      '& .MuiSvgIcon-root': {
        fontSize: '2.5rem',
      }
    }
  },
  titleFormPayment: {
    margin: '52px',
    flex: 1,
    color: theme.palette.text.secondary,
  },
  titleWaiting: {
    marginTop: '50px',
  },
  formControl: {

    minWidth: 400,
  },
  textObs: {
    color: theme.palette.text.secondary,
    fontSize: '13px',
  },
  totalValue: {
    fontSize: 17,
  },
  drawerKey:{
    maxWidth: '800px',
    margin: '0 auto',
  },
  containerMain:{
    marginBottom:'400px'
  },
}));


interface IPaymentFormData {
  name: string,
  number: string,
  expiry: string,
  cvc: string,
  acceptsTerms: boolean,
  parcelas: number,
}
export interface IPaymentVoucher {
  code: string,
  id: number,
  isConvenio: boolean,
  valorDescontoProduto: number,
  valorDescontoContrato: number,
  valorDescontoAdesao: number,
  valorDescontoCredito: number,
  valorDescontoContratoDCC: number;
  valorDescontoServico: number,
  qtdParcelasDesconto?: number,

}

const Checkout= () => {
  const theme = useTheme();
  const classes = useStyles();
  let timerId = 0;
  const [loading, setLoading] = React.useState(false);
  const [formPayment, setFormPayment] = React.useState<FormPaymentEnum>(FormPaymentEnum.CartaoCredito);
  const [disabled, setDisabled] = React.useState(false);
  const [loadingDebito, setLoadingDebito] = React.useState(false);
  const [loadingCredito, setLoadingCredito] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [contract, setContract] = React.useState();
  const [openModal, setOpenMoldal] = React.useState(false);
  const [isStopped, setIsStopped] = React.useState(true);
  const [idOrder, setIdOrder] = React.useState("");
  const [creditSelected, setCreditSelected] = React.useState(false);
  const [openParcelas, setOpenParcelas] = React.useState(false);
  const [aguardandoPagamento, setAguardandoPagamento] = React.useState(false);
  const [parcelas, setParcelas] = React.useState<IParcelaItem[]>([]);
  const [parcelaSelected, setParcelaSelected] = React.useState<IParcelaItem | undefined>();
  const [plan, setPlan] = React.useState<IPlanItem | undefined>();
  const [voucher, setVoucher] = React.useState<IPaymentVoucher>();
  const [paymentPending, setPaymentPending] = React.useState<IPaymentPending | undefined>();
  const [defaultParcela, setDefaultParcela] = React.useState<number>(0);
  const refRecaptcha = useRef<Recaptcha | null>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { updateUser, tenant } = useAuth();
  const [openDetails, setOpenDetails] = React.useState(false);

  const [exibeTeclado, setExibeTeclado] = React.useState(true);
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState();
  const [typeCard, setTypeCard] = useState<string>();

  const numberRef = useRef<HTMLInputElement | null>(null);

  const [inputName, setInputName] = useState("default");
  const [customLayout, setCustomLayout] = useState<any>();

  const [layout, setLayout] = useState("default");
  const layoutExpiry = {
    default: [
      "1 2 3",
      "4 5 6",
      "7 8 9",
      "/ 0 ",
      "{bksp}"
    ]
  };
  const layoutNumeric = {
    default: [
      "1 2 3",
      "4 5 6",
      "7 8 9",
      " 0 ",
      "{bksp}"
    ]
  };
  const keyboard = useRef(null);


  

  const handleCloseParcelas = useCallback((button) => {

    setExibeTeclado(true);
    
  }, [layout]);

  const handleOpenParcelas = useCallback((button) => {
    setExibeTeclado(false);
  }, [layout]);

  const onKeyPress = useCallback((button) => {

    if (button === "{shift}" || button === "{lock}" || layout === "shift") {
      handleShift(layout);
    }
    
  }, [layout]);
  const handleShift = useCallback((layoutName: string) => {

    console.log(layoutName)
    setLayout(layoutName === "default" ? "shift" : "default")
  }, []);

  const changeFocus = useCallback((name) => {
    setInputName(name);
    setFocus(name)
  },[]);

  useEffect(() => {
    
    if (inputName == "number") {
      setCustomLayout(layoutNumeric);
    }
    if (inputName == "name") {
      setCustomLayout(undefined);
    }
    if (inputName == "expiry") {
      setCustomLayout(layoutExpiry);
    }
    if (inputName == "cvc") {
      setCustomLayout(layoutNumeric);
    }
   
    
  }, [inputName]);
  
  const onChangeInput = useCallback((input, name) => {
    
    console.log(input, name)
    if (name == "number") {
      setNumber(input);
      setValue("number", input);
    }
    if (name == "name") {
      setName(input);
      setValue("name", input);
    }
    if (name == "expiry") {
      setExpiry(input);
      //setValue("mobile", input);
    }
    if (name == "cvc") {
      setCvc(input);
     // setValue("cep", input);
    }
   
    
  }, []);
 

  const timerProps = {
    isPlaying: true,
    size: 40,
    strokeWidth: 3
  };


  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: animationData,
  };

  const recaptchaLoaded = useCallback(() => {
    console.log('recaptcha load')
  }, [])

  const recaptchaCallback = useCallback((response) => {
    sessionStorage.setItem('@HubfitTotem:recap', response);
    setDisabled(false);
    console.log(response)
  }, [])


  useEffect(() => {
    sessionStorage.removeItem('@HubfitTotem:errorPayment');
    sessionStorage.removeItem('@HubfitTotem:payment_details');
    sessionStorage.removeItem('@HubfitTotem:voucher');
    sessionStorage.removeItem('@HubfitTotem:responsePayment');

    const planString = sessionStorage.getItem('@HubfitTotem:plan');
    const paymentString = sessionStorage.getItem('@HubfitTotem:payment');
    const actionString = sessionStorage.getItem('@HubfitTotem:action');
    if (actionString == 'pending_payment' && paymentString) {
      const paymentParsed = JSON.parse(paymentString);
      setPaymentPending(paymentParsed);
      let parcelasEdit: IParcelaItem[] = [];
      for (let i = 1; i <= paymentParsed.MaxParcelas; i++) {
        parcelasEdit.push({ value: i, text: paymentParsed.ValorTotal / i })
      }
      setParcelas(parcelasEdit);
    } else if (actionString == 'plan_payment' && planString) {
      const planParsed = JSON.parse(planString);
      if (planParsed) {
        setPlan(planParsed);
      }
      if (planParsed.payConfig.creditCard == false && planParsed.payConfig.pix == false && planParsed.payConfig.boleto == true) {
        setFormPayment(FormPaymentEnum.Boleto);
      }
      let voucher: IPaymentVoucher = { isConvenio: false, valorDescontoCredito: 0, valorDescontoProduto: 0, valorDescontoServico: 0, id: 0, code: "", valorDescontoContrato: 0, valorDescontoContratoDCC: 0, valorDescontoAdesao: 0 };

      api.get('/vouchers/plan/' + planParsed.id).then((response) => {
        voucher = response.data;
        sessionStorage.setItem('@HubfitTotem:voucher', JSON.stringify(response.data));
        setVoucher(response.data);
        let parcelasEdit: IParcelaItem[] = [];
        for (let i = 1; i <= planParsed.parcelas; i++) {
          parcelasEdit.push({
            value: i, text: (planParsed.dcc ? planParsed.valorDcc - (voucher && voucher.valorDescontoContrato > 0 ?
              voucher.qtdParcelasDesconto && voucher.qtdParcelasDesconto > 0 ? voucher.valorDescontoContratoDCC : voucher.valorDescontoContratoDCC
              : 0)
              : ((planParsed.valorContrato - (voucher ? voucher.valorDescontoContrato : 0)) +
                (planParsed?.adesaoParcelar ? planParsed.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0) : 0) +
                (planParsed?.servicoParcelar ? planParsed.servicoExtraValor - (voucher ? voucher.valorDescontoServico : 0) : 0) +
                (planParsed?.produtoParcelar ? planParsed.produtoValor - (voucher ? voucher.valorDescontoProduto : 0) : 0) +
                (planParsed.creditoValor - (voucher ? voucher.valorDescontoCredito : 0))
              ) / i)
          })
        }
        console.log("parcelas", parcelasEdit);
        setParcelas(parcelasEdit);
        setDefaultParcela(planParsed.parcelas);
      })

      //Ver limite de compra do plano
      api.get('/plans/limit', {
        params: {
          id: planParsed.id,
        }
      }).then((response) => {

        if (response.data.blocked === true) {
          addToast({
            type: "error",
            title: 'Plano limitado',
            description: "Você atingiu o limite máximo de compras do plano escolhido."
          });
          history.push("/plans/list")
        }

      }).catch((err) => {

      })

    } else {
      sessionStorage.removeItem('@HubfitTotem:plan');
      sessionStorage.removeItem('@HubfitTotem:action');
      sessionStorage.removeItem('@HubfitTotem:payment');
      history.goBack();
    }
    setTimeout(() => {
      numberRef.current?.focus();
    }, 1000)

    return () => {
      if (timerId && timerId > 0) {
        clearTimeout(timerId);
      }

    };

  }, [])


  const handleChangeCard = useCallback((e) => {
    setTypeCard(e.issuer);
  }, [setTypeCard]);




  let RegisterPasswordSchema = Yup.object().shape({
    name: Yup.string().required('Insira o nome impresso no cartão'),
    number: Yup.string().required('Insira o número do cartão de crédito'),
    expiry: Yup.string().required('Insira a validade do cartão'),
    cvc: Yup.string().required('Insira o código de segurança'),
    acceptsTerms: Yup.boolean().oneOf([true], "Você deve aceitar os termos do contrato"),
  });

  const { register, handleSubmit, control, errors, getValues, setValue } = useForm<IPaymentFormData>({
    resolver: yupResolver(RegisterPasswordSchema)
  });

  useEffect(() => {
    register({ name: 'parcelas' }, { required: true });
  }, [register])



  const onSubmit = useCallback(async (data: IPaymentFormData) => {

    if ((plan || paymentPending) && !loading) {
      try {
        setLoading(true);
        setDisabled(true);
        setSuccess(false);
        const cardForm: CreditCardDTO = {
          numero: data.number,
          ano: Number.parseInt(data.expiry.split('/')[1]),
          mes: Number.parseInt(data.expiry.split('/')[0]),
          nomeCompleto: data.name,
          cvc: data.cvc,
          bandeira: typeCard ? typeCard : 'visa',

        };
        const saleForm: IPaymentSaleDTO = {
          idPacote: plan ? plan.id : paymentPending ? paymentPending.Identificador : "",
          formaPagamento: FormPaymentEnum.CartaoCredito,
          parcelas: data.parcelas,
          idTenant: tenant.idTenant,
          cartao: cardForm,
          recaptcha: sessionStorage.getItem('@HubfitTotem:recap'),
          codeVoucher: voucher ? voucher.code : null,
          idVoucher: voucher ? voucher.id : null,
        };
        console.log(saleForm);

        if (plan) {

          const response = plan.isRenovacao ? await api.post('/payments/renew/checkout', saleForm) : await api.post('/payments/sales/checkout', saleForm);
          console.log(response.data);
          if (response.data.ok == true) {
            if (response.data.idPessoa) {
              //Atualizar ID e Token do usuário que foi convertido
              const userLocal = sessionStorage.getItem('@HubfitTotem:user');
              if (userLocal) {
                const userObj: User = JSON.parse(userLocal);
                userObj.type = TypeUser.Aluno;
                userObj.id = response.data.idPessoa;
                updateUser(userObj, response.data.token);
              }

            }
            sessionStorage.setItem('@HubfitTotem:paymentSuccess', JSON.stringify({
              bandeira: typeCard ? typeCard : 'visa',
              numero: '********' + data.number.substr(data.number.length - 4),
              formaPagamento: FormPaymentEnum.CartaoCredito,
              parcelas: data.parcelas,

            }));
            if (response.data.habilitaAgendamento) {
              sessionStorage.setItem('@HubfitTotem:habilitaAgendamento', 'true');
            }
            setSuccess(true);
            setTimeout(() => {
              history.push('/payments/checkout/success');
            }, 1000);
          } else {
            setLoading(true);
            setDisabled(false);
            addToast({
              type: "error",
              title: 'Ops...',
              description: response.data.msg,
              time: 20000
            });
          }
        } else if (paymentPending) {
          const response = await api.post('/payments/pending/checkout', saleForm);
          console.log(response.data);
          if (response.data.ok == true) {
            sessionStorage.setItem('@HubfitTotem:paymentSuccess', JSON.stringify({
              bandeira: typeCard ? typeCard : 'visa',
              numero: '********' + data.number.substr(data.number.length - 4),
              formaPagamento: FormPaymentEnum.CartaoCredito,
              parcelas: data.parcelas,

            }));
            setSuccess(true);
            setTimeout(() => {
              history.push('/payments/checkout/success');
            }, 1000);
          } else {
            setDisabled(false);
            addToast({
              type: "error",
              title: 'Ops...',
              description: response.data.msg,
              time: 20000
            });
          }
        }

      } catch (err) {
        setDisabled(false);
        addToast({
          type: "error",
          title: 'Erro no pagamento',
          description: "Não foi possível efetuar o pagamento",
          time: 20000
        });
      } finally {
        setLoading(false);
        refRecaptcha.current?.reset();
      }
    }
  }, [plan, history, paymentPending, tenant, voucher]);

  const handleClickOpenModalContrato = useCallback(async () => {
    try {
      const response = await api.get('/plans/' + plan?.idContrato + '/document?', {
        params: {
          value: plan?.valorTotal
        }
      });
      setContract(response.data);
    } catch (err) {
      setContract(undefined)
      addToast({
        type: "error",
        title: 'Erro no buscar o contrato',
        time: 15000
      });
    } finally {
      setOpenMoldal(true);

    }
  }, [plan]);

  const handleTimerOut = useCallback(() => {
    clearTimeout(timerId);
    cancelaTransacao(idOrder)
    setLoadingDebito(false);
    setLoadingCredito(false);
    sessionStorage.removeItem('@HubfitTotem:plan');
    sessionStorage.removeItem('@HubfitTotem:action');
    sessionStorage.removeItem('@HubfitTotem:payment');
    history.goBack();

  }, [timerId, idOrder])

  const handleGoBack = useCallback(() => {
    clearTimeout(timerId);
    setLoadingDebito(false);
    setLoadingCredito(false);
    sessionStorage.removeItem('@HubfitTotem:plan');
    sessionStorage.removeItem('@HubfitTotem:action');
    sessionStorage.removeItem('@HubfitTotem:payment');
    history.goBack();

  }, [timerId, idOrder])

  const getStatusTransacao = useCallback(async (idOrderT: string) => {
    const response = await api.get('/payments/sales/checkoutstatus', {
      params: {
        idOrder: idOrderT
      }
    });
    return response;
  }, []);
  const cancelaTransacao = useCallback(async (idOrderT: string) => {
    if (idOrderT) {
      const response = await api.delete('/payments/sales/checkoutcancel', {
        params: {
          idOrder: idOrderT
        }
      });
      return response;
    }
  }, []);


  const LoopStatus = useCallback((idOrderT: string) => {
    //  if (loadClass) {       //  create a loop function
    console.log(idOrderT)
    const timer = (idO: string) => window.setTimeout(function () {   //  call a 3s setTimeout when the loop is called
      getStatusTransacao(idO).then((response) => {
        console.log(response.data);
        const retorno = response.data;
        if (retorno.statusCode == 12) {
          //Ainda pendente
        } else if (retorno.statusCode == 2) {
          //Processado
          clearTimeout(timerId);
          if (response.data.ok == true) {
            if (retorno.retornoPaymentInfo) {
              sessionStorage.setItem('@HubfitTotem:paymentSuccess', JSON.stringify({
                bandeira: retorno.retornoPaymentInfo.brand,
                numero: retorno.retornoPaymentInfo.lastNumbers,
                formaPagamento: retorno.retornoPaymentInfo.formaPagamento == 2 ? FormPaymentEnum.CartaoCredito : FormPaymentEnum.CartaoDebito,
                parcelas: retorno.retornoPaymentInfo.parcelas,
                valor: retorno.retornoPaymentInfo.valor,
              }));
            }
            setSuccess(true);
            setTimeout(() => {
              history.push('/payments/checkout/success');
            }, 1000);
          } else {
            setDisabled(false);
            addToast({
              type: "error",
              title: 'Ops...',
              description: response.data.msg,
              time: 20000
            });
          }


        } else if (retorno.statusCode == 13) {
          //Operação cancelada
          setDisabled(false);
          addToast({
            type: "error",
            title: 'Operação cancelada',
            time: 7000
          });
          handleGoBack();
        }
      })


      LoopStatus(idOrderT);             //  ..  again which will trigger another
    }, 3000);
    timerId = timer(idOrderT);

    // }
  }, [])


  const formSubmitStone = useCallback((formSale: IPaymentSaleDTO) => {

    setLoading(true);
    setDisabled(true);
    setSuccess(false);
    if (plan) {
       api.post('/payments/sales/checkout', formSale).then((response) => {
        console.log(response.data);
        if (response.data.ok == true) {
          setIdOrder(response.data.idOrder)
          setAguardandoPagamento(true);
          setIsStopped(false);
          LoopStatus(response.data.idOrder);
        } else {
          setLoading(true);
          setDisabled(false);
          addToast({
            type: "error",
            title: 'Ops...',
            description: response.data.msg,
            time: 20000
          });
        }

      });
    } else if (paymentPending) {
      const response = api.post('/payments/pending/checkout', formSale).then((response) => {
        console.log(response.data);
        if (response.data.ok == true) {
          setIdOrder(response.data.idOrder)
          setAguardandoPagamento(true);
          setIsStopped(false);
          LoopStatus(response.data.idOrder);
        } else {
          setLoading(true);
          setDisabled(false);
          addToast({
            type: "error",
            title: 'Ops...',
            description: response.data.msg,
            time: 20000
          });
        }

      });
    }

  }, [setIdOrder, plan, paymentPending]);

  const handleParcelaClose = useCallback(() => {
    setOpenParcelas(false);

  }, [])
  const handleParcelaOpen = useCallback(() => {
    setOpenParcelas(true);
  }, []);

  const handleChangeParcela = useCallback(async (event: React.ChangeEvent<{ value: unknown }>) => {

    const parcela = (parcelas?.filter((item) => item.value == event.target.value)[0]);
    console.log(parcela);
    if (parcela) {
      setLoadingCredito(true);
      setLoadingDebito(false);
      setParcelaSelected(parcelas?.filter((item) => item.value == event.target.value)[0]);
      setOpenParcelas(false);
      const saleForm: IPaymentSaleDTO = {
        idPacote: plan ? plan.id : (paymentPending ? paymentPending.Identificador : ""),
        formaPagamento: FormPaymentEnum.CartaoCredito,
        parcelas: parcela.value,
        idTenant: tenant.idTenant,
        recaptcha: sessionStorage.getItem('@HubfitTotem:recap'),
        idMaquininha: plan ? plan.payConfig.idStone: paymentPending?.payConfig.idStone,
      };
      formSubmitStone(saleForm);
    }

  }, [parcelas, plan, paymentPending, tenant])

  const handleClickForm = useCallback(async (metodo: string) => {



    if (metodo == "credit") {
      
      setCreditSelected(true);
      setOpenParcelas(true);
    } else {
      setLoadingDebito(true);
      setLoadingCredito(false);
      const saleForm: IPaymentSaleDTO = {
        idPacote: plan ? plan.id : (paymentPending ? paymentPending.Identificador : ""),
        formaPagamento: metodo == "credit" ? FormPaymentEnum.CartaoCredito : FormPaymentEnum.CartaoDebito,
        parcelas: 1,
        idTenant: tenant.idTenant,
        recaptcha: sessionStorage.getItem('@HubfitTotem:recap'),
        idMaquininha: plan ? plan?.payConfig.idStone : paymentPending?.payConfig.idStone,
      };

      formSubmitStone(saleForm);
    }

  }, [plan, paymentPending, tenant]);
  const handleMoldalClose = () => {
    setOpenMoldal(false);
  };
  const handleClickDetails = useCallback(() => {
    setOpenDetails(true);
  }, [])
  const handleCloseModalDetails = useCallback(() => {
    setOpenDetails(false);
  }, []);
  return (
    <>
      <Header isBack avatar />
      <Container className={classes.containerMain}>
        <Grid container >
          <Grid item xs={12} sm={7} md={7} lg={8} >
            <AnimationContainer className={classes.animationTab}>
              <Background>
                <Grid container direction="row"
                  justify="center"
                  alignItems="center">
                  {aguardandoPagamento &&
                    <Grid justify="center" item xs={12} sm={12} md={10} lg={10} >
                      <div >

                        <Grid container direction="row"
                          justify="center"
                          alignItems="center">
                          <Grid className={classes.cardPadding}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                              {/* <CountdownCircleTimer
                                {...timerProps}
                                isPlaying
                                duration={90}
                                colors={['#56c972', '#F7B801', '#A30000', '#A30000']}
                                colorsTime={[30, 15, 5, 0]}
                                onComplete={handleTimerOut}
                              >
                                {({ remainingTime }: any) => (
                                  <span style={{ color: theme.palette.grey[500] }}>
                                    {remainingTime}
                                  </span>
                                )}

                              </CountdownCircleTimer> */}
                            </Grid>
                            <Grid container alignItems="center" >
                              <Grid item md >
                                <Typography gutterBottom variant="h5" className={classes.titleWaiting} >
                                  Aguardando pagamento na maquininha
                                </Typography>
                              </Grid>

                            </Grid>
                            <Typography color="textSecondary" variant="body2">
                              Insira seu cartão ou Aperte o botão OK e aproxime na maquininha.
                            </Typography>
                            <div>
                              <Lottie options={defaultOptions}
                                height={300}
                                width={300}
                                speed={1}
                                isStopped={isStopped}
                              />

                            </div>

                            <Grid item xs={12} alignItems="center" direction="column" style={{ display: 'flex', marginTop: '55px' }} >
                              <Button type="button" variant="outlined" color="primary" size="large" onClick={handleTimerOut} startIcon={<ArrowBackIos />}>
                                Cancelar
                              </Button>
                            </Grid>

                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  }
                  {aguardandoPagamento == false &&
                    <Grid item xs={12} sm={12} md={8} lg={6} className={classes.cardPadding}>
                      {(plan?.payConfig.idStone && plan?.dcc == false) || (paymentPending?.payConfig.idStone) ?
                        <>
                          <Grid container
                            direction="column"
                            justify="center"
                            alignItems="center">
                            <div className={classes.titleFormPayment}>
                              <h3 >Escolha a forma de pagamento</h3>

                            </div>
                          </Grid>
                          <Grid spacing={3} container
                            direction="row"
                            justify="center"
                            alignItems="center">

                            <Grid item xs={12} sm={6} md={6} lg={5}>
                              <Button disabled={loadingCredito} fullWidth onClick={() => handleClickForm("credit")} variant="contained" size="large" color="primary" className={classes.margin}>
                                <CreditCard />
                                Crédito
                                {loadingCredito && <CircularProgress size={40} className={classes.buttonProgressForm} />}
                              </Button>
                           

                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={5}>
                              <Button disabled={loadingDebito} fullWidth onClick={() => handleClickForm("debit")} variant="contained" size="large" color="primary" className={classes.margin}>
                                <CreditCard />
                                Débito
                                {loadingDebito && <CircularProgress size={40} className={classes.buttonProgressForm} />}
                              </Button>

                            </Grid>
                          </Grid>
                          {creditSelected &&
                            <Grid spacing={3} container
                              direction="column"
                              justify="center"
                              alignItems="center">
                              <Grid item xs={12}>
                                <FormControl fullWidth className={classes.formControl} style={{ marginTop: '40px' }}>
                                  <InputLabel id="parcelasLabel">Parcelas</InputLabel>

                                  <Select open={openParcelas}
                                    onClose={handleParcelaClose}
                                    onOpen={handleParcelaOpen}
                                    name="parcelasMaquininha"
                                    value={parcelaSelected ?? ""}
                                    onChange={handleChangeParcela}
                                  >
                                    <MenuItem ><b>Parcelas</b></MenuItem>
                                    {parcelas.map(p => (
                                      <MenuItem key={p.value} value={p.value} style={{ fontSize: '20px' }}>
                                        {`${p.value}x de `} <NumberFormat style={{ marginLeft: '5px' }} value={p.text} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />
                                      </MenuItem>

                                    ))}

                                  </Select>
                                </FormControl>

                              </Grid>
                            </Grid>
                          }

                          <Grid item xs={12} alignItems="center" direction="column" style={{ display: 'flex', marginTop: '70px' }} >
                            <Button type="button" variant="outlined" color="primary" size="large" onClick={handleTimerOut} startIcon={<ArrowBackIos />}>
                              Cancelar
                            </Button>
                          </Grid>
                        </>
                        :
                        <>
                          <Cards

                            cvc={cvc}
                            expiry={expiry}
                            focused={focus}
                            name={name}
                            number={number}
                            locale={{ valid: 'valido até' }}
                            placeholders={{ name: 'NOME NO CARTÃO' }}
                            callback={handleChangeCard}
                          />
                          <form onSubmit={handleSubmit(onSubmit)}>

                            <Typography variant="h5" gutterBottom className={classes.titleCard}>
                              Dados do cartão de crédito
                            </Typography>
                            <Paper elevation={1} className={classes.Paper} >
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <FormControl fullWidth error={!!errors.number?.message}>

                                    <InputLabel htmlFor="number">Número</InputLabel>
                                    {/* <InputMask
                                      mask="9999 9999 9999 999999"
                                      disabled={false}
                                      maskChar=""
                                      onFocus={(e) => {changeFocus(e.target.name); }} 
                                      onChange={(e) => {onChangeInput(e.target.value, "number"); }}
                                      value={number} 

                                    >

                                      {() => */}
                                       <Input
                                      
                                      onFocus={(e) => {changeFocus(e.target.name); }} 
                                      onChange={(e) => {onChangeInput(e.target.value, "number"); }}
                                      value={number} 
                                      name="number" id="number" autoComplete="off" inputRef={(e) => {
                                        register(e);
                                        numberRef.current = e;
                                      }}

                                      />
                                      {/* }
                                    </InputMask> */}


                                    {/* <NumberFormat customInput={TextField}  displayType={'text'} format="#### #### #### ####" name="number" id="number" onFocus={(e) => setFocus(e.target.name)} onChange={(e) => setNumber(e.target.value)}  autoComplete="off"
                            inputRef={(e: HTMLInputElement | null) => {
                              register(e);
                              numberRef.current = e;
                            }}/> */}
                                    <FormHelperText>{errors.number?.message}</FormHelperText>
                                  </FormControl>

                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl fullWidth error={!!errors.name?.message}>
                                    <InputLabel htmlFor="name">Nome no cartão</InputLabel>
                                    <Input name="name" id="name" 
                                    onFocus={(e) => {changeFocus(e.target.name);}}
                                     onChange={(e) => {onChangeInput(e.target.value, "name"); }} 
                                     value={name}
                                     inputRef={register} autoComplete="off" />
                                    <FormHelperText>{errors.name?.message}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth error={!!errors.expiry?.message}>
                                    <InputLabel htmlFor="expiry">Validade</InputLabel>
                                    {/* <InputMask
                                      mask="99/99"

                                      disabled={false}
                                      maskChar=""
                                      value={expiry}
                                      onFocus={(e) =>{changeFocus(e.target.name);}} 
                                      onChange={(e) => {onChangeInput(e.target.value, "expiry");}}
                                    >
                                      {() =>  */}
                                      <Input  value={expiry}
                                      onFocus={(e) =>{changeFocus(e.target.name);}} 
                                      onChange={(e) => {onChangeInput(e.target.value, "expiry");}} name="expiry" id="expiry" inputRef={register} autoComplete="off" />
                                       {/* }
                                    </InputMask> */}
                                    <FormHelperText>{errors.expiry?.message}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth error={!!errors.cvc?.message}>
                                    <InputLabel htmlFor="cvc">CVC</InputLabel>
                                    {/* <InputMask
                                      mask="9999"
                                      disabled={false}
                                      maskChar=""
                                      onFocus={(e) => {changeFocus(e.target.name);}} 
                                      onChange={(e) => {onChangeInput(e.target.value, "cvc");}}
                                      value={cvc}
                                    >
                                      {() =>  */}
                                      <Input  onFocus={(e) => {changeFocus(e.target.name);}} 
                                      onChange={(e) => {onChangeInput(e.target.value, "cvc");}}
                                      value={cvc} name="cvc" id="cvc" inputRef={register} autoComplete="off" />
                                        {/* }
                                     </InputMask> */}
                                    <FormHelperText>{errors.cvc?.message}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl fullWidth error={!!errors.parcelas?.message}>
                                    <InputLabel id="parcelasLabel">Parcelas</InputLabel>
                                    <Controller
                                      as={
                                        <Select onClose={handleCloseParcelas}
                                        onOpen={handleOpenParcelas} >
                                          {parcelas.map(p => (
                                            <MenuItem key={p.value} value={p.value}>
                                              {`${p.value}x de `} <NumberFormat value={p.text} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} />
                                            </MenuItem>

                                          ))}

                                        </Select>
                                      }
                                      name="parcelas"
                                      rules={{ required: "Escolha as parcelas" }}
                                      control={control}
                                    />
                                    {plan && (plan?.adesaoValor - (voucher && voucher.valorDescontoAdesao > 0 ? voucher.valorDescontoAdesao : 0)) > 0 && plan.adesaoParcelar == false &&
                                      <small> + 1x de <NumberFormat value={plan?.adesaoValor - (voucher && voucher.valorDescontoAdesao > 0 ? voucher.valorDescontoAdesao : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /> ({plan.adesaoDescricao})</small>
                                    }
                                    {plan && (plan?.servicoExtraValor - (voucher && voucher.valorDescontoServico > 0 ? voucher.valorDescontoServico : 0)) > 0 && plan.servicoParcelar == false &&
                                      <small> + 1x de <NumberFormat value={plan?.servicoExtraValor - (voucher && voucher.valorDescontoServico > 0 ? voucher.valorDescontoServico : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /> ({plan.servicoExtraDescricao})</small>
                                    }
                                    <FormHelperText>{errors.parcelas?.message}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                {plan && plan.idContrato &&
                                  <Grid item xs={12}>
                                    <FormControl fullWidth error={!!errors.acceptsTerms?.message} >
                                      <div className={classes.ControlTerms}>
                                        <FormControlLabel className={classes.labelControlTerms}
                                          control={
                                            <Checkbox
                                              // checked={state.checkedB}
                                              // onChange={handleChange}
                                              inputRef={register}
                                              name="acceptsTerms"
                                              color="primary"
                                            />
                                          }
                                          label="Eu aceito os termos do "
                                        />
                                        <Button variant="text" onClick={handleClickOpenModalContrato} className={classes.buttonContract} >Contrato</Button>
                                      </div>
                                      <FormHelperText>{errors.acceptsTerms?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                }
                              </Grid>
                            </Paper>

                            <Content>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                              >
                                <Recaptcha
                                  ref={refRecaptcha}
                                  hl='pt-BR'
                                  size='normal'
                                  sitekey="6LfHOKIUAAAAAPwaZ0c_LxLmhZV4wckS4xGKr9Nr"
                                  render="explicit"
                                  onloadCallback={recaptchaLoaded}
                                  verifyCallback={recaptchaCallback}
                                />
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                              >
                                {!success &&
                                  <Button className={buttonClassname} disabled={disabled} type="submit" variant="contained" color="primary"  >

                                    {loading &&
                                      <>
                                        <span>Aguarde...</span><CircularProgress size={24} className={classes.buttonProgress} />
                                      </>
                                    }
                                    {!loading &&
                                      <span>Efetuar pagamento</span>
                                    }
                                  </Button>
                                }
                                {success && !loading &&
                                  <CheckCircle fontSize="large" htmlColor="#4caf50" className={classes.IconCheck} />
                                }

                              </Grid>
                            </Content>
                          </form>
                        </>
                      }
                    </Grid>
                  }
                </Grid>
              </Background>
            </AnimationContainer>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={4} >
            {paymentPending && (
              <Grid container direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12} md={10} lg={8} className={classes.cardPadding}>
                  <Card>
                    <CardBody>
                      {paymentPending.MaxParcelas > 1 && (
                        <>
                          <Typography align="center">Em até {paymentPending.MaxParcelas}x de</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={paymentPending.ValorTotal / paymentPending.MaxParcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                        </>
                      )}
                      {paymentPending.MaxParcelas == 1 && (
                        <>
                          <Typography align="center">à vista</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={paymentPending.ValorTotal / paymentPending.MaxParcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                        </>
                      )}


                    </CardBody>
                    <Divider variant="fullWidth" />
                    <CardBody>
                      <GridFooterCard>
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Valor original:</p></Grid>
                          <Grid><NumberFormat value={paymentPending.ValorOriginal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Multa / Juros:</p></Grid>
                          <Grid><NumberFormat value={paymentPending.ValorMulta} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Total:</p></Grid>
                          <Grid><NumberFormat value={paymentPending.ValorTotal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>
                      </GridFooterCard>
                    </CardBody>

                  </Card>
                </Grid>
              </Grid>

            )}
            {plan && (
              <Grid container direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12} md={10} lg={8} className={classes.cardPadding}>
                  <Card>
                    <CardHeader >
                      <Typography variant="h4">{plan.nomePlano}</Typography>
                    </CardHeader>

                    <CardBody>
                      {plan.parcelas > 1 && !plan.dcc && (
                        <>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorTotal-plan.adesaoValor} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                          <Typography align="center">Em até {plan.parcelas}x</Typography>
                        </>
                      )}
                      {plan.parcelas == 1 && !plan.dcc && (
                        <>
                          <Typography align="center">à vista</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorTotal-plan.adesaoValor / plan.parcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                        </>
                      )}
                      {plan.dcc && (
                        <>
                          <Typography align="center">Mensalidade</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorDcc} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                          <Typography variant="body2" className={classes.textObs} align="center">Cobrado mensalmente no cartão de crédito</Typography>
                        </>
                      )}
                      <Grid container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center">
                        <Grid item xs={12} alignContent="center">
                          <Button fullWidth color="secondary" onClick={() => handleClickDetails()}>Mais detalhes</Button>
                        </Grid>

                      </Grid>
                    </CardBody>
                    <Divider variant="fullWidth" />

                    <CardBody>

                      {plan.descricaoContrato &&
                        <GridFooterCard>
                          <Grid container
                            direction="row"
                            justify="space-between"

                            alignItems="center">
                            <Typography variant="h6" gutterBottom>
                              {plan.descricaoContrato}
                            </Typography>
                            <Grid style={{ color: '#a0a0a0' }}>
                              {plan.parcelas > 1 ?
                                <span>
                                  Parcelado em até {plan.parcelas}x
                                </span>
                                :
                                plan.dcc ?

                                  <span>
                                    Mensalidade
                                  </span>
                                  :
                                  <span>
                                    1x à vista
                                  </span>
                              }
                            </Grid>
                          </Grid>


                          {voucher && (voucher.valorDescontoContrato > 0 || voucher.valorDescontoContratoDCC > 0) &&
                            <>
                              <Grid container
                                direction="row"
                                justify="space-between"
                                style={{ color: green[500] }}
                                alignItems="center">
                                <Grid>
                                  <p>Desconto:</p>

                                </Grid>
                                <Grid>-<NumberFormat value={voucher.valorDescontoContrato} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                              </Grid>
                              {voucher.qtdParcelasDesconto && voucher.qtdParcelasDesconto > 0 && plan.dcc &&
                                <Grid container
                                  direction="row"
                                  justify="flex-end"
                                  style={{ color: grey[500] }}
                                  alignItems="flex-end">
                                  {voucher.qtdParcelasDesconto > 1 ?
                                    <small>*Descontado nas {voucher.qtdParcelasDesconto} primeiras mensalidades</small>
                                    :
                                    <small>*Descontado na 1ª mensalidade</small>
                                  }
                                </Grid>
                              }
                            </>
                          }
                          {plan.dcc &&
                            <Grid container
                              direction="row"
                              justify="space-between"
                              alignItems="center">
                              <Grid><p>Mensalidade:</p></Grid>
                              <Grid><b><NumberFormat value={plan.valorDcc - (voucher ? voucher.valorDescontoContrato : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>

                            </Grid>
                          }
                          {!plan.dcc &&
                            <Grid container
                              direction="row"
                              justify="space-between"
                              alignItems="center">
                              <Grid><p>Total:</p></Grid>
                              <Grid><b><NumberFormat value={plan.valorContrato - (voucher ? voucher.valorDescontoContrato : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                            </Grid>
                          }


                          <Divider style={{ marginBottom: '10px' }} />
                        </GridFooterCard>
                      }

                      {plan.adesaoDescricao &&
                        <GridFooterCard>
                          <Grid container
                            direction="row"
                            justify="space-between"

                            alignItems="center">
                            <Typography variant="h6" gutterBottom>
                              {plan.adesaoDescricao}
                            </Typography>
                            <Grid style={{ color: '#a0a0a0' }}>
                              {plan.adesaoParcelar && plan.parcelas > 1 ?
                                <span>
                                  Parcelado em até {plan.parcelas}x
                                </span>
                                :
                                <span>
                                  <span>
                                    1x à vista
                                  </span>
                                </span>
                              }
                            </Grid>
                          </Grid>

                          {voucher && voucher.valorDescontoAdesao > 0 &&

                            <Grid container
                              direction="row"
                              justify="space-between"
                              style={{ color: green[500] }}
                              alignItems="center">
                              <Grid>
                                <p>Desconto:</p>

                              </Grid>
                              <Grid>-<NumberFormat value={voucher.valorDescontoAdesao} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>
                            </Grid>
                          }
                          <Grid container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid><p>Total:</p></Grid>
                            <Grid><b><NumberFormat value={plan.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                          </Grid>



                          <Divider style={{ marginBottom: '10px' }} />
                        </GridFooterCard>
                      }
                      {plan.servicoExtraDescricao &&
                        <GridFooterCard>
                          <Grid container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Typography variant="h6" gutterBottom>
                              {plan.servicoExtraDescricao}
                            </Typography>
                            <Grid style={{ color: '#a0a0a0' }}>
                              {plan.servicoParcelar && plan.parcelas > 1 ?
                                <span>
                                  Parcelado em até {plan.parcelas}x
                                </span>
                                :
                                <span>
                                  <span>
                                    1x à vista
                                  </span>
                                </span>
                              }
                            </Grid>
                          </Grid>



                          {voucher && voucher.valorDescontoServico > 0 &&

                            <Grid container
                              direction="row"
                              justify="space-between"
                              style={{ color: green[500] }}
                              alignItems="center">
                              <Grid>
                                <p>Desconto:</p>

                              </Grid>
                              <Grid>-<NumberFormat value={voucher.valorDescontoServico} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>
                            </Grid>
                          }
                          <Grid container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid><p>Valor:</p></Grid>
                            <Grid><b><NumberFormat value={plan.servicoExtraValor - (voucher ? voucher.valorDescontoServico : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                          </Grid>



                          <Divider style={{ marginBottom: '10px' }} />
                        </GridFooterCard>
                      }
                      {plan.anuidadeValor > 0 &&
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Manutenção anual:</p></Grid>
                          <Grid><NumberFormat value={plan.anuidadeValor} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>
                      }

                      {voucher &&


                        <Grid container
                          direction="row"
                          justify="space-between"
                          style={{ color: green[500] }}
                          alignItems="center">
                          {voucher.isConvenio ?
                            <Grid>
                              <p>Desconto aplicado <small>({voucher.code})</small>:</p>
                            </Grid>
                            :
                            <Grid>
                              <p>Desconto aplicado <small>({voucher.code})</small>:</p>
                            </Grid>
                          }

                          <Grid>-<NumberFormat value={(plan.adesaoValor > 0 ? voucher.valorDescontoAdesao : 0) +
                            (plan.valorContrato > 0 ? voucher.valorDescontoContrato : 0) +
                            (plan.produtoValor > 0 ? voucher.valorDescontoProduto : 0) +
                            (plan.servicoExtraValor > 0 ? voucher.valorDescontoServico : 0)} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>
                        </Grid>
                      }
                      {plan.dcc &&
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><b className={classes.totalValue}>Você paga agora:</b></Grid>
                          <Grid><b className={classes.totalValue}><NumberFormat value={(plan.valorDcc - (voucher ? voucher.valorDescontoContrato : 0)) + (plan.adesaoValor - (voucher ? voucher.valorDescontoAdesao : 0))
                          } displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                        </Grid>
                      }
                      {!plan.dcc &&
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><b className={classes.totalValue}>Valor total:</b></Grid>
                          <Grid><b className={classes.totalValue}><NumberFormat value={plan.valorTotal - (voucher ?
                            (plan.adesaoValor > 0 ? voucher.valorDescontoAdesao : 0) +
                            (plan.valorContrato > 0 ? voucher.valorDescontoContrato : 0) +
                            (plan.produtoValor > 0 ? voucher.valorDescontoProduto : 0) +
                            (plan.servicoExtraValor > 0 ? voucher.valorDescontoServico : 0) : 0)
                          } displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></b></Grid>
                        </Grid>
                      }
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>

            )}

          </Grid>
        </Grid>
      </Container>
      <Dialog fullScreen open={openModal} onClose={handleMoldalClose} >
        <AppBar className={classes.appBar}>
          <Toolbar>

            <Typography variant="h6" className={classes.title}>
              Contrato
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleMoldalClose} aria-label="close" className={classes.buttonClose} >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.backgroundContract}>
          <Grid container alignItems="center" justify="center" direction="row" >
            <Grid item xs={12} sm={12} md={10} lg={8} >
              <Paper elevation={3} className={classes.paperContract} >
                {ReactHtmlParser(contract ? contract : "Nenhum contrato foi encontrado. Verifique com a empresa.")}
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <DialogPlanDetails onClose={handleCloseModalDetails} open={openDetails} plan={plan} />

      {tenant.virtual_key && exibeTeclado &&
    <Drawer
      anchor={"bottom"}
      variant="permanent"
      open={true}
     // className={classes.drawerKey}
     classes={{
      paper: classes.drawerKey
    }}
     
    >
        <Keyboard

                  inputName={inputName}
                  keyboardRef={r => (keyboard.current = r)}
                  layoutName={layout}
                  layout={customLayout}
                  theme={"hg-theme-default myTheme1"}
                  onChange={(e) => onChangeInput(e,inputName)}
                  onKeyPress={onKeyPress}
                  mergeDisplay={true}
                  display={{
                    '{bksp}': '&#8592',
                  }}
                />

    </Drawer>
   }


    </>
  )
};
export default Checkout;

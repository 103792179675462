import React, { useEffect, useState, useCallback } from 'react';
import { Switch, BrowserRouter, Router, useLocation, useHistory, withRouter } from 'react-router-dom';

import Route from './Route';
import IdleTimer from 'react-idle-timer';
import SignIn from '../pages/Login/SignIn';
import Home from '../pages/Home';
import SignUp from '../pages/Login/SignUp';
import Dashboard from '../pages/Dashboard';
import PlansList from '../pages/Plans/List';
import ClassesList from '../pages/Classes/List';

import AppointmentsList from '../pages/Appointments';
import UserRegistration from '../pages/User/Registration';

import PaymentChoice from '../pages/Payments/Choice';
import PaymentChekout from '../pages/Payments/Checkout';
import PaymentChekoutSuccess from '../pages/Payments/Checkout/Success';

import Error404 from '../pages/Errors/404';

import { useAuth } from '../hooks/auth';
import { useToast } from '../hooks/toast';
import PaymentsPending from '../pages/Payments/Pending';
import { ThemeProvider, createMuiTheme, Theme, makeStyles } from '@material-ui/core/styles';
import { Background } from '../styles';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, DialogContentText, CircularProgress } from '@material-ui/core';
import logo from '../assets/img/logo-hubfit.svg';
import background from '../assets/img/bg.jpg';
import axios from 'axios';
import Training from '../pages/Training';
import { Tenant } from '../models/Tenant/TenantDTO';
import SignOut from '../pages/Login/SingOut';




const useStyles = makeStyles((theme: Theme) => ({
  logoHubfit: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& img': {
      height: '65px',
    }
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));



const Routes= () => {
  const classes = useStyles();
  const { addToast } = useToast();
  let location = useLocation();
  const history = useHistory();
  const { user, tenant, signInTenant, updateTenant, signOut } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingTotem, setLoadingTotem] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState();

  const handleClose = () => {

  };



  const handleOk = useCallback(async () => {
    setLoadingTotem(true);
    try {
      await signInTenant({
        domain: location.pathname.split('/')[1],
        idbranch: Number(location.pathname.split('/')[2]),
        code,
      });



      setOpen(false);
      setLoading(false);


      history.push('/' + location.pathname.split('/')[1] + '/' + location.pathname.split('/')[2]);
     // window.location.reload();
    } catch (err) {
      addToast({
        type: "error",
        title: 'Erro',
        description: err.message,
        time: 20000
      });
    } finally {
      setLoadingTotem(false);
    }
  }, [code])
  const handleChangeCode = useCallback((event) => {
    //console.log(event.target.value);
    setCode(event.target.value);

  }, [code])

  useEffect(() => {
    if (tenant?.localApiUrl) {
      axios.get('http://'+tenant.localApiUrl+':5001/api/totem/status').then((response) => {
        tenant.localApiStatus = true;
        tenant.fingerprintReader = response.data.fingerprintReader;
        tenant.printer = response.data.printer;
        updateTenant(tenant)
     
      }).catch((err) => {
        if (tenant.localApiStatus != false) {
          tenant.localApiStatus = false;
          updateTenant(tenant)
        }
      })
    }

    
  }, [tenant]);

  useEffect(
    () => {
      setLoading(true);
      const fetchData = async () => {
        try {
          console.log(location.pathname)
          const domain = location.pathname.split('/')[1];
          const idBranch = Number(location.pathname.split('/')[2]);

          if (domain && idBranch) {
            if (tenant) {


              if (domain.toLowerCase() != tenant.domain.toLowerCase() || idBranch != tenant.idBranch) {
                //Dados diferentes, vai fazer chamada de unidade
                setOpen(true);
              } else {
                //Dados iguais, fazer nova busca para atualização
                await signInTenant({
                  domain: location.pathname.split('/')[1],
                  idbranch: Number(location.pathname.split('/')[2]),
                  idtotem: tenant.idTotem,
                  code: 'none'
                }).catch((error) => {
                  console.log(error)
                  addToast({
                    type: "error",
                    title: 'Erro',
                    description: error.message,
                    time: 20000
                  });
                });

              }
              setLoading(false);
            }
            else {

              //sem tenant no storage, fazer nova chamada
              setOpen(true);
            }

          } else {

            if (location.pathname != '/404') {
              history.replace('/404');
            }
            setLoading(false);
          }


        } catch (err) {
          console.log('errro', err);
          //  localStorage.removeItem('@HubfitTotem:tenant');
          history.replace('/404');
          setLoading(false);
        } finally {

        }
      }
      fetchData();
    },
    []
  )

  const [theme, setTheme] = useState<Theme>(createMuiTheme());
  useEffect(() => {

    if (tenant) {


      const theme = createMuiTheme({
        palette: {
          primary: {
            main: `${tenant.primaryColorTheme ? tenant.primaryColorTheme : '#1976d2'}`,
          },
          secondary: {
            main: `${tenant.secundaryColorTheme ? tenant.secundaryColorTheme : '#115293'}`,
          },
        },
        overrides: {
          MuiButton: {
            containedPrimary: {
              color: `${tenant.textColorTheme ? tenant.textColorTheme : '#ffffff'}`,
            },
          },
          MuiAppBar: {
            colorPrimary: {
              color: `${tenant.textColorTheme ? tenant.textColorTheme : '#ffffff'}`,
            }
          }
        }
      });
      theme.typography.h3 = {
        fontSize: '1.9rem',
        '@media (min-width:600px)': {
          fontSize: '1.8rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '2rem',
        },
      }
      theme.typography.h4 = {
        fontSize: '1.0rem',
        '@media (min-width:600px)': {
          fontSize: '1.2rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.2rem',
        },
      }
      theme.typography.h6 = {
        fontSize: '1.0rem',
        '@media (min-width:600px)': {
          fontSize: '1.2rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.2rem',
        },
      }
      setTheme(theme);
    }
    // }
  }, [tenant]);

  const handleIdleUser = useCallback(() => {
    console.log('SignOut automático')
    if (user) {
      signOut();
    }
  }, [user])




  return (

    <>

      <Background
        style={{
          backgroundImage: "url(" + `${tenant ? tenant.url_Background : background}` + ")",
          backgroundSize: "cover",
          zIndex: 0,
          backgroundPosition: "top center",
        }}
      >
        <ThemeProvider theme={theme}>

          <IdleTimer
            timeout={60 * 1000}
            //  onActive={this.handleOnActive}
            onIdle={handleIdleUser}
            //  onAction={this.handleOnAction}
            debounce={250}
          />

          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className={classes.logoHubfit}>
              <img src={logo} alt="Hubfit" />
            </div>
            <DialogTitle id="form-dialog-title">Confirmação de acesso</DialogTitle>
            <DialogContent>

              <DialogContentText>
                Digite o código de acesso configurado para este totem.
    </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="codeText"
                label="Código"
                type="text"
                onChange={handleChangeCode}
              />
            </DialogContent>
            <DialogActions>

              <Button disabled={loadingTotem} onClick={handleOk} color="primary">
                Confirmar
                {loadingTotem &&
                  <CircularProgress size={24} className={classes.buttonProgress} />
                }
              </Button>
            </DialogActions>
          </Dialog>


          {!loading &&
            <BrowserRouter  basename={`/${tenant?.domain}/${tenant?.idBranch}`}>
              <Switch>

                <Route path="/" exact component={Home} />
                <Route path="/404" component={Error404} />
                <Route path="/login" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/sair" component={SignOut} />
                <Route path="/dashboard" component={Dashboard} isPrivate />

                <Route path="/plans/list" component={PlansList} />

                <Route path="/classes/list" component={ClassesList} />


                <Route path="/payments/choice" component={PaymentChoice} isPrivate />
                <Route path="/payments/checkout" exact component={PaymentChekout} isPrivate />
                <Route path="/payments/checkout/success" component={PaymentChekoutSuccess} isPrivate />
               <Route path="/payments/pending" component={PaymentsPending} isPrivate /> 
               

                <Route path="/appointments" exact component={AppointmentsList} isPrivate />

                <Route path="/training" component={Training} isPrivate />

                <Route path="/user/registration" component={UserRegistration} isPrivate />

                {/* <Route component={Error404} /> */}
              </Switch>
            </BrowserRouter>
          }




        </ThemeProvider>
      </Background>



    </>
  )
};

export default withRouter(Routes);

import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from './styles';
import classNames from "classnames";
import Lottie from 'react-lottie';
import animationData from '../../../../assets/animations/success.json';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography, Divider } from '@material-ui/core';
import { ArrowBackIos, Today, PowerSettingsNew, ArrowForwardIos } from '@material-ui/icons';
import GridContainer from '../../../../components/Grid/GridContainer'
import GridItem from '../../../../components/Grid/GridItem'
import Header from '../../../../components/Header';
import { useAuth } from '../../../../hooks/auth';
import NumberFormat from 'react-number-format';
import { FormPaymentEnum } from '../../../../models/Payments/IPaymentSaleDTO';
import DialogAvatar from '../../../../components/DialogAvatar/Index';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",

    },
    right: {
      marginLeft: "auto",
    },
    detailPayment: {
      marginTop: "10px"
    },
    avatar: {
      maxWidth: "160px",

      margin: "0 auto",
    },
    name: {
      marginTop: "-80px"
    },
    imgFluid: {
      maxWidth: "100%",
      height: "auto"
    },
    imgRaised: {
      boxShadow:
        "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRoundedCircle: {
      borderRadius: "50% !important"
    },
    Paper: {
      padding: '20px',
      marginBottom: '26px',
    },
    cardPadding: {
      padding: '16px',
    },
    dividerMargin: {
      margin: '20px 0',
    },
    wrapper: {
      marginTop: '30px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    }
  }),
);
interface DescricaoItem {
  descricao: string;
}
interface IPayment {
  bandeira: string;
  numero: string;
  formaPagamento: FormPaymentEnum;
  parcelas: number;
  habilitaAgendamento: boolean;
  valor: number;
}
interface IPlanItem {
  id: string;
  bloqueio: string;
  nomePlano: string;
  descricaoPlano: string;
  duracao: string;
  validadeCredito: string;
  idContrato: number;
  credito: boolean;
  idCredito: number;
  parcelas: number;
  valorTotal: number;
  adesaoObservacao: string;
  anuidadeValor: number;
  anuidadeMes: number;
  servicoExtraObservacao: string;
  adesaoDescricao: string;
  servicoExtraDescricao: string;
  creditoDescricao: string;
  produtoDescricao: string;
  creditoObservacao: string;
  produtoObservacao: string;
  adesaoValor: number;
  dcc: boolean;
  valorDcc: number;
  caracteristicas: Array<DescricaoItem>;
}
interface IPaymentsPending {
  Id: string;
  Data: Date;
  Vencimento: Date;
  VencimentoSerialized: string;
  Valor: number;
  JurosMulta: number;
  ValorTotal: number;
  Descricao: string;
}

const CheckoutSuccess= () => {
  const classes = useStyles();
  const { tenant, signOut } = useAuth();
  const [plan, setPlan] = React.useState<IPlanItem | undefined>();
  const [payment, setPayment] = React.useState<IPaymentsPending | undefined>();
  const [paymentSucess, setPaymentSuccess] = React.useState<IPayment | undefined>();
  const [isStopped, setIsStopped] = React.useState(true);
  const [new_register, setNew_register] = React.useState(false);
  const [habilitaAgendamento, setHabilitaAgendamento] = React.useState(false);
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
  };

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const history = useHistory();

  useEffect(() => {
    const planString = sessionStorage.getItem('@HubfitTotem:plan');
    const paymentString = sessionStorage.getItem('@HubfitTotem:payment');
    const paymentSuccessString = sessionStorage.getItem('@HubfitTotem:paymentSuccess');
    const newR = sessionStorage.getItem('@HubfitTotem:new_register');
    const habAgend = sessionStorage.getItem('@HubfitTotem:habilitaAgendamento');
    if (newR) {
      if (newR == "true") {
        setNew_register(true);
      }
    }
    if (habAgend) {
      if (habAgend == "true") {
        setHabilitaAgendamento(true);
      }
    }
    setIsStopped(false);
    sessionStorage.removeItem('@HubfitTotem:plan');
    sessionStorage.removeItem('@HubfitTotem:payment');
    sessionStorage.removeItem('@HubfitTotem:paymentSuccess');
    sessionStorage.removeItem('@HubfitTotem:action');
    if (!!planString && !!paymentSuccessString) {
      const planParsed = JSON.parse(planString);
      const payParsed = JSON.parse(paymentSuccessString);
      if (planParsed) {
        setPlan(planParsed);
      }
      if (payParsed) {
        setPaymentSuccess(payParsed);
      }
    } else if (!!paymentString && !!paymentSuccessString) {
      const payPendingParsed = JSON.parse(paymentString);
      const payParsed = JSON.parse(paymentSuccessString);
      if (payPendingParsed) {
        setPayment(payPendingParsed);
      }
      if (payParsed) {
        setPaymentSuccess(payParsed);
      }
    } else {
      history.push('/dashboard');
    }

  }, [])

  const handleClickReturn = useCallback(() => {
    history.push('/dashboard');
  }, [history]);
  const handleClickAgendar = useCallback(() => {
    history.push({
      pathname: '/appointments',
      state: {
        agendar: true,
      }
    })

  }, [history]);

  const handleClickLogout = useCallback(() => {
    signOut();
    history.push('/');
  }, [signOut, history]);

  const handleClickWizardNewRegister = useCallback(() => {
    history.push("/user/registration")
  },[])

  return (
    <>
      <Header isBack={false} avatar linkPush="/dashboard" />
      <div className={classes.mainRaised}>
        <div>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} md={10}>
                <div >
                  <div>
                    <Lottie options={defaultOptions}
                      height={200}
                      width={200}
                      speed={0.6}
                      isStopped={isStopped}
                    />

                  </div>
                  <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid className={classes.cardPadding}>
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center">
                          <Grid item xs >
                            <Typography gutterBottom variant="h5" >
                              Pagamento realizado com sucesso
                          </Typography>
                          </Grid>

                        </Grid>
                        <Typography color="textSecondary" variant="body2">
                          Enviamos um e-mail para você com a confirmação e todos os detalhes deste pagamento.
                          </Typography>
                        <Divider variant="fullWidth" className={classes.dividerMargin} />
                        {plan &&
                          <>
                            <Typography variant="body1" gutterBottom className={classes.detailPayment} >
                              <b>Item da compra:</b> {plan?.nomePlano}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <b>Valor:</b> <NumberFormat value={(paymentSucess?.valor && paymentSucess?.valor> 0) ?paymentSucess?.valor: plan?.valorTotal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /> em {paymentSucess?.parcelas}x
                          </Typography>
                          </>
                        }
                        {payment &&
                          <>
                            <Typography variant="body1" gutterBottom>
                              <b>Valor:</b> <NumberFormat value={(paymentSucess?.valor && paymentSucess?.valor> 0) ?paymentSucess?.valor: payment?.ValorTotal} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /> em {paymentSucess?.parcelas}x
                    </Typography>
                          </>
                        }
                        <Typography variant="body1" gutterBottom className={classes.detailPayment} >
                          <b>Cartão:</b> {paymentSucess?.numero} - {paymentSucess?.bandeira}
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          spacing={2}
                          style={{ marginTop: '23px' }}
                        >
                          {!new_register &&
                            <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>
                              <Button type="button" variant="outlined" color="primary" size="large" onClick={handleClickReturn} startIcon={<ArrowBackIos />}>
                                Voltar
                          </Button>
                            </Grid>
                          }
                          {!new_register && paymentSucess?.habilitaAgendamento && tenant.permissions.appointments &&
                            <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>

                              <Button type="button" variant="contained" color="primary" size="large" onClick={handleClickAgendar} startIcon={<Today />}>
                                Agendar
                         </Button>

                            </Grid>
                          }
                          {!new_register && (paymentSucess?.habilitaAgendamento == false || tenant.permissions.appointments == false) &&
                            <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>

                              <Button type="button" variant="contained" color="primary" size="large" onClick={handleClickLogout} startIcon={<PowerSettingsNew />}>
                                Sair
                         </Button>

                            </Grid>
                          }
                          {new_register &&
                            <Grid item xs={12} sm={6} alignItems="center" direction="column" style={{ display: 'flex' }}>

                              <Button type="button" variant="contained" color="primary" size="large" onClick={handleClickWizardNewRegister} startIcon={<ArrowForwardIos />}>
                                Continuar
                     </Button>

                            </Grid>
                          }
                        </Grid>

                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      </div>
    </>
  )
};
export default CheckoutSuccess;

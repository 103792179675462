import React, { useCallback, useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { format, setDay, isBefore, addDays, parseISO } from 'date-fns';
import classNames from "classnames";

import { ContentModal } from './styles';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Typography, Button, Grid, Divider,
  DialogContent as MuiDialogContent, Dialog, CircularProgress, CardMedia, CardContent, Card, Chip, Paper, Avatar, AppBar, Tabs, Tab, Box, useTheme, List, ListItem, ListItemAvatar, ListItemText, CardHeader, IconButton, ListItemSecondaryAction, DialogTitle,
  TextField
} from "@material-ui/core";
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import { Content } from "./styles";
import api from '../../services/api';
import ReactPlayer from 'react-player';

import { useToast } from '../../hooks/toast';
import Header from '../../components/Header';
import Timer from '../../components/TimerCountdown';
import { red, green } from '@material-ui/core/colors';
import { useAuth } from '../../hooks/auth';
import { IClassItem, IVerify, IReturnVerify } from '../../models/Classes/IClassesDTO';
import { ArrowLeft, PlayArrow, Print, CheckCircle, Close, SentimentDissatisfied, FitnessCenter,BorderColorRounded, Edit } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Treino, Exercicio } from '../../models/Training/TrainingDTO';
import { ptBR } from 'date-fns/locale';
import axios from 'axios';
import GifPlayer from "react-gif-player";
import { Checkbox } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    rootLoading: {
      display: 'flex',
      cursor: 'default',
      opacity: '0.8',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    vagas: {
      borderRadius: '23px',
      height: '48px',
      fontSize: '32px',

    },
    divVagas: {
      display: 'flex',
      alignItems: 'center',
      width: '138px',
      flexDirection: 'column',
      alignSelf: 'center',
      // marginTop: '-16px',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 200,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    titleClass: {
      margin: '2px 5px 8px 14px !important',
    },
    timeClass: {
      margin: '0 !important',
      color: theme.palette.text.secondary,
    },
    timeRest: {
      color: theme.palette.text.disabled,
      marginLeft: '10px',
      fontSize: '14px',
    },
    divFlexTime: {
      display: 'inline-flex',
      alignItems: 'flex-end'

    },
    textVagas: {
      color: theme.palette.text.secondary,
    },
    textConfirmado: {
      background: green[500],
      height: '32px',
      fontSize: '19px',
      borderRadius: '23px',
    },
    coverModal: {
      width: 'auto',
      height: '185px',
      backgroundColor: '#e4e4e4',
    },
    titleLigh: {
      color: theme.palette.text.disabled,
    },
    titleCoverModal: {
      position: 'absolute',
      top: '25%',
      left: '50%',
      color: 'white',
      transform: 'translate(-50%, -50%)',
      textShadow: '1px 2px 4px #000000',
    },
    contentModal: {
      position: 'relative',
      height: '385px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    btnRegister: {
      position: 'absolute',
      right: '15px',
      top: '-18px',
    },
    cardText: {
      textAlign: 'center',
    },
    contentDialog: {
      marginTop: '10px',
    },
    section1: {
      margin: '4px 16px 14px',
    },
    section2: {
      margin: theme.spacing(2),
    },
    buttonRed: {
      color: theme.palette.getContrastText(red[500]),
      background: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    disableRegister: {

      color: 'rgb(0 0 0 / 55%)',
      backgroundColor: 'rgb(189 189 189 / 84%)',
      '&:hover': {
        backgroundColor: 'rgb(189 189 189 / 84%)',
      },
      cursor: 'inherit',
    },
    noClasses: {
      textAlign: 'center',
      padding: '60px',
      opacity: '0.7',
      '& h5': {
        color: theme.palette.text.secondary,
      }
    },
    divNoPlay: {
      marginLeft: '56px',
      marginTop: '38px'
    },
    paperTraining: {
      marginBottom: '17px',
      marginLeft: '70px'
    },
    paperTitulo: {
      marginLeft: '70px'
    },
    buttonPrint: {
      marginTop: '0px',
      marginRight: '0px',
    },
    dataExerc: {
      margin: '0 2px'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    GridList: {
      margin: '28px 0 !important',
    },
    iconSmile: {
      fontSize: '5.1875rem',
    }, btnBack: {
      margin: '12px 0',
    }
  }),
);



const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Training= () => {
  const classes = useStyles();
  const theme = useTheme();
  const [training, setTraining] = useState<Treino | undefined>();
  const [tab, setTab] = useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const { tenant } = useAuth();

  const [loadingData, setLoadingData] = React.useState(true);
  const [noTraining, setNoTraining] = React.useState(false);
  const [idTrainingToday, setIdTrainingToday] = React.useState(0);
  const [exercicioPlayer, setExercicioPlayer] = React.useState<Exercicio>();
  const [printing, setPrinting] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [openModalCarga, setOpenModalCarga] = useState(false);
  const [novaCarga, setNovaCarga] = useState(0);
  const [checkedExercises, setCheckedExercises] = React.useState({});
  const [ exerciseID, setexerciseID ] = useState()
  const [ atualizacao, setatualizacao] = useState(false)

  const handleChangeCarga = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      setNovaCarga(value);
    }
  };

  const handleOpenModalCarga = (exercicioID, Carga) => {
    setexerciseID(exercicioID)
    setNovaCarga(Carga)
    setOpenModalCarga(true);
  };

  const handleFinalizar = (treino) => {
    console.log(treino)

    const idsSelecionados = treino
    .filter(t => t.showFirst === true) // Pega os objetos com showFirst true
    .map(t => t.id); // Extrai apenas os IDs

    console.log("IDs selecionados:", idsSelecionados[0]);


    

    api.put('/training/done/'+idsSelecionados[0]).then(response => {
      addToast({
        type: "success",
        title: 'Treino concluído',
        description: response.data.message
      });

      setCheckedExercises({}); 
      localStorage.removeItem('checkedExercises');

      setatualizacao(prev => !prev);

    })
  }
  
  const handleCloseModalCarga = () => {
    setOpenModalCarga(false);
  };
  
  useEffect(() => {
    setLoadingData(true);
    
    api.get('/training', {
      params: {
        idBranch: tenant.idBranch
      }
    }).then(response => {
      setLoadingData(false);
      if (!response.data) {
        setNoTraining(true);
      } else {
        setTraining(response.data);
        console.log(response.data)
        const dados = response.data as Treino;
        const showTraining = dados.series.filter(serie => {
          return serie.showFirst == true;
        })
        if (showTraining.length > 0) {
          setIdTrainingToday(showTraining[0].id)
          const indexTraining = dados.series.indexOf(showTraining[0]);
          setTab(indexTraining);
        }
      }
    }).catch(() => {
      addToast({
        type: "error",
        title: 'Erro de conexão',
        description: "Não foi possível se conectar ao servidor"
      });
    })

    const storedCheckedExercises = localStorage.getItem('checkedExercises');
    if (storedCheckedExercises) {
      setCheckedExercises(JSON.parse(storedCheckedExercises));
    }

  }, [atualizacao])

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  const handleChangeIndex = (index: number) => {
    setTab(index);
  };

  const handlePrintClick = useCallback(async () => {
    const idSerie = training?.series[tab].id;
    try {
      setPrinting(true);
      await axios.post('http://'+tenant.localApiUrl+":5001/api/training/print?idserie=" + idSerie, training);

      api.put('/training', null, {
        params: {
          idserie: idSerie,
          idbranch: tenant.idBranch
        }
      })
    } catch (err) {
      console.log(err)
      addToast({
        type: "error",
        title: 'Erro de impressão',
        description: "Não foi possível se conectar com a impressora"
      });
    } finally {
      setPrinting(false);
    }
  }, [tab, training, tenant.localApiUrl]);

  const handleClickExercise = useCallback((tipo, exercicio, e) => {
   
    if (tipo === 'checkbox') {
      e.stopPropagation();
      
      setCheckedExercises((prevState) => {
        const newState = {
          ...prevState,
          [exercicio]: !prevState[exercicio],
        };
  
        // Salvar no LocalStorage
        localStorage.setItem('checkedExercises', JSON.stringify(newState));
  
        return newState;
      });
    } else { 
      setExercicioPlayer(exercicio);
      setOpenModal(true);
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, [])
  
  const handleClickReturn = useCallback(() => {
    history.goBack();
  }, [history])

  const handleSalvarCarga = useCallback(() => {
    api.put('/training/update/'+exerciseID,{
      carga: novaCarga
      }).then(response => {
        setatualizacao(prev => !prev);
        addToast({
          type: "success",
          title: 'Carga Alterada',
          description: 'Carga alterada com sucesso',
          time: 10000
        });
      })
    setOpenModalCarga(false);
  }, [exerciseID,novaCarga])


  return (
    <>
      <Header linkPush="/dashboard" avatar />
      <div>
        <div>
          <Content>
            <Grid container spacing={3} direction="row"
              justify="center"
              alignItems="center" >
              <Grid item xs={12} sm={12} md={10} lg={8} >
                {noTraining && !loadingData &&
                  <Paper className={classes.paperTraining}>
                    <Grid container direction="column" className={classes.GridList}
                      justify="center"
                      alignItems="center" >

                      <h4 >Nenhum treino ativo</h4>
                      <SentimentDissatisfied className={classes.iconSmile} />

                      <Button onClick={handleClickReturn} className={classes.btnBack}>
                        <ArrowLeft />
                          Voltar
                      </Button>
                    </Grid>
                  </Paper>
                }
                {loadingData &&
                  <Paper className={classes.paperTraining}>
                    <Grid container direction="column"
                      justify="center"
                      alignItems="center" >
                      <Grid item xs={12} className={classes.GridList}>
                        <CircularProgress size={40} />
                      </Grid>
                    </Grid>
                  </Paper>
                }
                {!loadingData && !noTraining &&
                  <>
                    <Paper className={classes.paperTraining}>
                      {training &&
                        <CardHeader
                          avatar={
                            <Avatar alt={training?.nameInstructor} src={training?.avatarInstructor} />
                          }
                          action={
                            <Button
                              disabled={printing || !tenant.printer || !tenant.localApiStatus}
                              variant="contained"
                              color="primary"
                              onClick={handlePrintClick}
                              startIcon={<Print />}
                            >
                              Imprimir
                          {printing &&
                                <CircularProgress size={24} className={classes.buttonProgress} />
                              }
                            </Button>
                          }
                          title={`Prof: ${training.nameInstructor}`}
                          subheader={<>  <Chip size="small" color="secondary" label={training.description} />  <Chip size="small" label={`criação: ${format(parseISO(training.start), 'dd/MM/yyyy', { locale: ptBR })}`} /> <Chip size="small" label={`vencimento: ${format(parseISO(training.valid), 'dd/MM/yyyy', { locale: ptBR })}`} /> <Chip size="small" label={`sessões: ${training.sessionsDone}/${training.sessions}`} /> </>}
                        />
                      }
                      {training &&
                        <CardHeader
                          action={
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleFinalizar(training?.series)}
                              startIcon={<CheckCircle />}
                            >
                              Finaliza Treino
                          {printing &&
                                <CircularProgress size={24} className={classes.buttonProgress} />
                              }
                            </Button>
                          }
                        />
                      }
                    </Paper>


                    <Paper className={classes.paperTraining}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={tab}
                          onChange={handleChangeTab}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="tabs" 
                        >
                          {training?.series.map(serie => (
                            <Tab key={serie.id} label={
                              serie.id == idTrainingToday ?
                                <>
                                  <Typography color="textPrimary" variant="body2">
                                    {serie.description}
                                  </Typography>
                                  <Typography color="textSecondary"  style={{ fontSize: '12px' }}>
                                    Treino de hoje
                                  </Typography>
                                </>
                                : <> {serie.description}</>
                            }  {...a11yProps(training?.series.indexOf(serie))} />
                          ))}
                        </Tabs>
                      </AppBar>

                      <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={tab}
                        onChangeIndex={handleChangeIndex}
                      >
                        {training?.series.map(serie => (
                          <TabPanel value={tab} index={training?.series.indexOf(serie)} dir={theme.direction}>
                            {serie.observation &&
                              <>
                                <div className={classes.section1}>
                                  <Typography color="textSecondary" variant="body2">
                                    {serie.observation}
                                  </Typography>
                                </div>
                                <Divider variant="middle" />
                              </>
                            }
                            <List component="nav">
                              {serie?.exercises.map(exercise => (
                                <>
                                    {!!exercise.url_gif || exercise.url_video || exercise.idyoutube ? 
                                      <ListItem
                                            button
                                            key={exercise.id}
                                            onClick={(e) => {  
                                              e.stopPropagation(); 
                                              handleClickExercise('checkbox', exercise.id, e); 
                                            }} 
                                            style={{
                                              backgroundColor: checkedExercises[exercise.id] ? '#DFFFD6' : 'transparent',
                                              transition: 'background-color 0.1s',
                                            }}
                                          >
                                          <Checkbox
                                            checked={checkedExercises[exercise.id] || false}
                                              onClick={(e) => {  
                                              e.stopPropagation(); 
                                              handleClickExercise('checkbox', exercise.id, e); 
                                            }}
                                            onChange={(e) => { 
                                            }}
                                            onMouseDown={(e) => {
                                              e.stopPropagation();  
                                            }}
                                            style={{
                                              color: checkedExercises[exercise.id] ? 'green' : 'initial',
                                              transition: 'color 0.1s',
                                            }}
                                          />
                                        <ListItemAvatar >
                                          <>
                                          {!!exercise.url_gif  ? (
                                            <GifPlayer
                                              gif={exercise.url_gif}
                                              style={{width: '50px', height:'39px', maxWidth:'100%', marginRight: 10}}
                                              onClick={(e) => handleClickExercise('list', exercise, e)}
                                              />
                                          ):( 
                                            <Avatar style={{width: '38px', height:'33px', maxWidth:'100%', marginRight:24}} >
                                              <PlayArrow
                                              onClick={(e) => handleClickExercise('list', exercise, e)}
                                              />
                                            </Avatar>
                                          )}
                                          </>
                                        </ListItemAvatar>

                                        <ListItemText primary={exercise.nameExercise} secondary={exercise.observation} />
                                          <ListItemSecondaryAction>
                                            <>
                                              {exercise.series &&
                                                <Chip label={`Séries: ${exercise.series}`} className={classes.dataExerc} />
                                              }
                                              {exercise.repetitions &&
                                                <Chip label={`Repet: ${exercise.repetitions}`} className={classes.dataExerc} />
                                              }
                                              {exercise.load ? (
                                                <Chip
                                                  label={`Carga: ${exercise.load}`}
                                                  className={classes.dataExerc}
                                                  onClick={() => handleOpenModalCarga(exercise.id, exercise.load)}
                                                  style={{ cursor: "pointer" }}
                                                  deleteIcon={
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                      <Edit fontSize="small" 
                                                      onClick={() => handleOpenModalCarga(exercise.id, exercise.load)}
                                                      />
                                                    </Box>
                                                  }
                                                  onDelete={() => {}}
                                                />
                                              ): (
                                                <Chip
                                                  label={`Carga`}
                                                  className={classes.dataExerc}
                                                  onClick={() =>handleOpenModalCarga(exercise.id, 0)}
                                                  style={{ cursor: "pointer" }}
                                                  deleteIcon={
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                      <Edit fontSize="small" 
                                                      onClick={() => handleOpenModalCarga(exercise.id, 0)}
                                                      />
                                                    </Box>
                                                  }
                                                  onDelete={() => {}}
                                                />
                                              )}
                                              
                                            </>
                                          </ListItemSecondaryAction>
                                      </ListItem>
                                      :
                                      <ListItem
                                            button
                                            key={exercise.id}
                                            onClick={(e) => {  
                                              e.stopPropagation(); 
                                              handleClickExercise('checkbox', exercise.id, e); 
                                            }} 
                                            style={{
                                              backgroundColor: checkedExercises[exercise.id] ? '#DFFFD6' : 'transparent',
                                              transition: 'background-color 0.1s',
                                            }}
                                          >
                                        <Checkbox
                                            checked={checkedExercises[exercise.id] || false}
                                              onClick={(e) => {  
                                              e.stopPropagation(); 
                                              handleClickExercise('checkbox', exercise.id, e); 
                                            }}
                                            onChange={(e) => { 
                                            }}
                                            onMouseDown={(e) => {
                                              e.stopPropagation();  
                                            }}
                                            style={{
                                              color: checkedExercises[exercise.id] ? 'green' : 'initial',
                                              transition: 'color 0.1s',
                                            }}
                                          />
                                        <div className={classes.divNoPlay}></div>
                                        <ListItemText primary={exercise.nameExercise} secondary={exercise.observation} />
                                        <ListItemSecondaryAction>
                                        </ListItemSecondaryAction>  
                                        
                                        <ListItemSecondaryAction>
                                          <>
                                            {exercise.series &&
                                              <Chip label={`Séries: ${exercise.series}`} className={classes.dataExerc} />
                                            }
                                            {exercise.repetitions &&
                                              <Chip label={`Repet: ${exercise.repetitions}`} className={classes.dataExerc} />
                                            }
                                            {exercise.load ? (
                                              <Chip
                                                label={`Carga: ${exercise.load}`}
                                                className={classes.dataExerc}
                                                onClick={() => handleOpenModalCarga(exercise.id , exercise.load)}
                                                style={{ cursor: "pointer" }}
                                                deleteIcon={
                                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Edit fontSize="small" 
                                                    onClick={() => handleOpenModalCarga(exercise.id, exercise.load)}
                                                    />
                                                  </Box>
                                                }
                                                onDelete={() => {}}
                                              />
                                            ): (
                                              <Chip
                                                  label={`Carga`}
                                                  className={classes.dataExerc}
                                                  onClick={() => handleOpenModalCarga(exercise.id , 0)}
                                                  style={{ cursor: "pointer" }}
                                                  deleteIcon={
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                      <Edit fontSize="small" 
                                                      onClick={() => handleOpenModalCarga(exercise.id, 0)}
                                                      />
                                                    </Box>
                                                  }
                                                  onDelete={() => {}}
                                                />
                                            )}
                                          </>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    }
                                  <Divider variant="inset" component="li" />
                                </>
                              ))}

                            </List>
                          </TabPanel>
                        ))}
                      </SwipeableViews>
                    </Paper>
                    
                  </>
                }
              </Grid>
            </Grid>
          </Content>
        </div>
      </div>


      <Dialog maxWidth="md" onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="h6">{exercicioPlayer?.nameExercise || "Nome não disponível"}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </DialogTitle>
        <ContentModal className={classes.contentModal}>
        {exercicioPlayer ? (
        exercicioPlayer.url_gif ? (
          <GifPlayer gif={exercicioPlayer.url_gif} style={{ marginLeft: "50px" }} />
        ) : exercicioPlayer.url_video ? (
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${exercicioPlayer.url_video.split("v=")[1]}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <Typography variant="body1">Nenhum vídeo disponível</Typography>
        )
      ) : (
        <Typography variant="body1">Carregando...</Typography>
      )}
        </ContentModal>


      </Dialog>


      <Dialog maxWidth="md" onClose={handleCloseModalCarga} open={openModalCarga}>
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography variant="h6">Alterar Carga</Typography>
            <IconButton aria-label="close" onClick={handleCloseModalCarga}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <div style={{ padding: "20px" }}>
          <Typography variant="body1">Digite a nova carga para o exercício:</Typography>
          <TextField
            type="number"
            value={novaCarga}
            onChange={handleChangeCarga}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ min: "0" }}
          />
          <Button variant="contained" color="secondary" onClick={handleSalvarCarga} style={{ marginTop: "10px" }}>
            Salvar
          </Button>
        </div>
      </Dialog>





    </>
  )

}



export default Training;

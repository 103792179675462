import React, { useCallback, ChangeEvent, useState } from 'react';
import axios from 'axios';
import classNames from "classnames";
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Button, Avatar, Grid, Badge } from "@material-ui/core";
import { Assignment, PersonPinCircle, MonetizationOn, AccountBox, PowerSettingsNew, Event, AddShoppingCart, CameraAlt, Fingerprint, FitnessCenter } from '@material-ui/icons';

import { Content } from "./styles";
import profile from "../../assets/img/christian.jpg";
import Header from '../../components/Header';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { shade } from 'polished';
import DialogAvatar from '../../components/DialogAvatar/Index';
import DialogBiometricRegister from '../../components/BiometricRegister/Index';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    right: {
      marginLeft: "auto",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    main: {
      position: "relative",
      zIndex: 3,
      background: "rgba(255, 255, 255, 0.9)",
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",

    },
    imgFluid: {
      maxWidth: "100%",
      height: "auto"
    },
    imgRaised: {
      boxShadow:
        "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRoundedCircle: {
      borderRadius: "50% !important"
    },
    profile: {
      textAlign: "center",
      // "& img": {
      //   maxWidth: "160px",
      //   width: "100%",
      //   margin: "0 auto",
      //   transform: "translate3d(0, -50%, 0)"
      // }
    },
    name: {
      marginTop: "-19px",
      color: theme.palette.common.white,
    },
    margin5: {
      margin: "5px"
    },
    margin: {
      margin: '8px 0',
      padding: '23px 55px',
      '& span': {
        display: 'flex !important',
        flexDirection: 'column !important',
        '& .MuiSvgIcon-root': {
          fontSize: '2.5rem',
        }
      }
    },
    large: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
    avatar: {
      maxWidth: "160px",

      margin: "0 auto",
    },


    avatarDiv: {
      position: 'relative',
      alignSelf: 'center',
    },
    inputAvatar: {
      display: 'none',
    },
    labelAvatar: {
      position: 'absolute',
      width: '48px',
      height: '48px',
      background: '#e6e6e6',
      borderRadius: '50%',
      right: '-11',
      bottom: '-10',
      border: '0',
      transition: 'background-color 0.2s',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        width: '20px',
        height: '20px',
        color: '#312e38',
      },
      '&:hover': {
        background: shade(0.2, '#e6e6e6'),
      }
    },
    btnFinger: {
      zIndex: 1,
      right: '-59px',
      bottom: '-4px',
    }
  },


  ),
);

const Dashboard= () => {
  const classes = useStyles();
  const { user, tenant, updateUser } = useAuth();
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [openModalBiometric, setOpenModalBiometric] = useState(false);
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const history = useHistory();
  const { addToast } = useToast();

  const handleAvatarChange = useCallback(() => {
    setOpenModalAvatar(true);

  }, [addToast, updateUser])

  const handleClickShop = useCallback(() => {
    history.push('/plans/list');
  }, [history]);
  const handleClickClasses = useCallback(() => {
    history.push('/classes/list');
  }, [history]);

  const handleClickAppointments = useCallback(() => {
    history.push('/appointments');
  }, [history]);

  const handleClickPayments = useCallback(() => {
    history.push('/payments/pending');
  }, [history]);
  const handleClickTraining = useCallback(() => {
    history.push('/training');
  }, [history])
  const handleCloseModalAvatar = useCallback(() => {
    setOpenModalAvatar(false);
  }, [])
  const handleCloseModalBiometric = useCallback(() => {
    axios.get('http://'+tenant.localApiUrl + ':5001/api/biometrics/readermode').catch((err) => {
    })
    setOpenModalBiometric(false);
  }, [tenant.localApiUrl])
  const handleOpenModalBiometric = useCallback(() => {
    setOpenModalBiometric(true);
  }, [])

  return (
    <>
      <Header removeBack avatar={false} />
      {/* <AppBar position="static">
    <Toolbar>

      <Button  className={classes.right}  color="inherit" onClick={handleLogout}>

        Sair
        <PowerSettingsNew />
        </Button>
    </Toolbar>
  </AppBar> */}

      <div className={classNames(classes.mainRaised)}>
        <div>
          <Content>
            <Grid container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={12} sm={12} lg={10}>
                <div className={classes.profile}>
                  <div className={classes.avatarDiv}>


                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={

                        tenant.permissions.changeAvatar &&
                        <label htmlFor="avatar" className={classes.labelAvatar} onClick={handleAvatarChange}>
                          <CameraAlt />

                        </label>

                      }
                    >

                      {tenant.localApiStatus && tenant.fingerprintReader &&
                        <label htmlFor="avatar" className={classNames(classes.labelAvatar, classes.btnFinger)} onClick={handleOpenModalBiometric} >
                          <Fingerprint />
                        </label>
                      }
                      <Avatar alt={user.name} src={user.avatarURL} className={classNames(classes.large, classes.avatar)} />
                    </Badge>


                    {/* <img src={profile} alt="..." className={imageClasses} /> */}
                  </div>
                  <div className={classes.name}>
                    <h3 >Olá {user.name}</h3>
                  </div>
                  <Grid spacing={3} container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
               <Button fullWidth variant="contained" size="large" color="primary" className={classes.margin}>
                 <AccountBox />
                Cadastro
              </Button>
              </Grid> */}
                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
               <Button fullWidth variant="contained" size="large" color="primary" className={classes.margin}>
                 <Assignment />
                Meus planos
              </Button>
              </Grid> */}
                    {tenant.permissions.classesView &&
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Button fullWidth onClick={handleClickClasses} variant="contained" size="large" color="primary" className={classes.margin}>
                          <PersonPinCircle />
                Aulas
              </Button>
                      </Grid>
                    }
                    {tenant.permissions.training &&
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Button fullWidth onClick={handleClickTraining} variant="contained" size="large" color="primary" className={classes.margin}>
                          <FitnessCenter />
                Treino
              </Button>
                      </Grid>
                    }
                    {tenant.permissions.appointments &&
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Button fullWidth onClick={handleClickAppointments} variant="contained" size="large" color="primary" className={classes.margin}>
                          <Event />
                Agendamentos
              </Button>
                      </Grid>
                    }
                    {tenant.permissions.payments &&
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Button fullWidth onClick={handleClickPayments} variant="contained" size="large" color="primary" className={classes.margin}>
                          <MonetizationOn />
                Financeiro
              </Button>
                      </Grid>
                    }
                    {(tenant.permissions.newSales || tenant.permissions.renewSales) &&
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Button fullWidth onClick={handleClickShop} variant="contained" size="large" color="primary" className={classes.margin}>
                          <AddShoppingCart />
                  Comprar
                </Button>
                      </Grid>
                    }
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      </div>
      <DialogAvatar onClose={handleCloseModalAvatar} open={openModalAvatar} />
      <DialogBiometricRegister onClose={handleCloseModalBiometric} opened={openModalBiometric} open={openModalBiometric} />
    </>
  )

}



export default Dashboard;

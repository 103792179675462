import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from '@material-ui/pickers';
import InputMask from "react-input-mask";
import Recaptcha from 'react-recaptcha';
import DateFnsUtils from '@date-io/date-fns';
import { parse, isDate, format, parseISO } from "date-fns";
import ptLocale from "date-fns/locale/pt-BR";
//import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation, RouteComponentProps } from 'react-router-dom';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Header from '../../../components/Header';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';


//import Input from '../../components/Input';
//import DatePicker from '../../components/DatePicker';


import { Container, Content, AnimationContainer, Background, ContentInfo } from './styles';
import { Input, InputAdornment, Button, makeStyles, Theme, CircularProgress, Grid, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Typography, Paper, CardContent, InputLabel, FormHelperText, Divider, TextField, Dialog, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, DialogContentText, Drawer, Box } from '@material-ui/core';
import { ArrowForwardIos, ArrowLeft, Email, PhoneAndroid, ArrowBackIos, AccountCircle, Check, } from '@material-ui/icons';
import { green, blue } from '@material-ui/core/colors';
import { useAuth } from '../../../hooks/auth';
import CardBody from '../../../components/Card/CardBody';
import { GridFooterCard } from '../../Plans/List/styles';
import { IPlanItem } from '../../../models/Plans/IPlanDTO';
import CardHeader from '../../../components/Card/CardHeader';
import Card from '../../../components/Card/Card';
import { Autocomplete } from '@material-ui/lab';
import SignInModal from '../SignInModal';
import DialogPlanDetails from '../../../components/DialogPlanDetails/Index';
import CardFooter from '../../../components/Card/CardFooter';
import Keyboard from "react-simple-keyboard";

const useStyles = makeStyles((theme: Theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: '60px 0 0',
    width: '100%',
    position: 'relative',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  SpanCard: {
    color: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',

  },
  IconInfo: {
    color: blue[500],
  },
  RingOff: {
    'outline-style': 'inherit',
  },
  Paper: {
    padding: '22px',
    marginBottom: '5px',
  },
  DatePicker: {
    fontSize: '22 !important',
    marginBottom: 0,
  },
  cardPadding: {
    padding: '16px',
    marginTop: '30px',
    flexDirection: 'column',
    display: 'flex',
  },
  animationTab: {
    height: '100%',
  },
  imageLogo: {
    width: '100%',
  },
  valuePlan: {
    marginTop: '2px !important',
  },
  divBack: {
    marginTop: '40px',
  },
  overflowCard: {
    overflow: 'inherit !important',
  },
  marginContent: {
    margin: '0px 25px',
  },
  containerMain: {
    marginBottom: '400px'
  },
  drawerKey: {
    maxWidth: '800px',
    margin: '0 auto',
  }
}));

interface ISignUpRouteData extends RouteComponentProps<
  { myParamProp?: string }, // this.props.match.params.myParamProp
  any, // history
  { myStateProp?: string } // this.props.location.state.myStateProp
> {
  cpf?: string,
}
interface ISignUpFormData {
  name: string,
  email: string,
  birth: string,
  cpf: string,
  gender: string,
  mobile: string,
  cep: string,
  address: string,
  number: string,
  neighborhood: string,
  password: string,
  idTenant: string,
  idUnidade: number,
  idCidade: number,
  recaptcha: string | null,
  idUserLocalized: number | null,
  typeUserLocalized: number | null,
}
interface ICity {
  name: string;
  id: string;
}
var dataForms: ISignUpFormData;
interface SignUpLoginFormData {
  password: string;
  password_confirmation: string;

}
interface UserLocalized {
  id: number;
  type: number;
}
export interface IUserLogin {
  avatarUrl: string;
  email: string;
  nome: string;
  id: number;
  tipoPessoa: number;
  cadastroProspect: boolean;
  cadastro: number;
  dependentes: IUserLogin[];
}

const SignUp = () => {
  const classes = useStyles();
  const { tenant, updateUser } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [loadingFinal, setLoadingFinal] = React.useState(false);
  const [loadingCity, setLoadingCity] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [plan, setPlan] = React.useState<IPlanItem | undefined>();
  const [cpfInput, setCpfInput] = React.useState<string | undefined>();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [selectedGender, setSelectedGender] = React.useState('feminino')
  const [openCity, setOpenCity] = React.useState(false)
  const nameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const { addToast } = useToast();
  const history = useHistory<ISignUpRouteData>();
  const [rua, setRua] = React.useState()
  const [bairro, setBairro] = React.useState();
  const [cidade, setCidade] = React.useState<ICity | null>(null);
  const [optionsCity, setOptionsCity] = React.useState<ICity[]>([]);
  const [inputCity, setInputCity] = React.useState('');
  const refRecaptcha = useRef<Recaptcha | null>(null);
  const [userLocalized, setUserLocalized] = React.useState<UserLocalized>();
  const [openModalLogin, setOpenModalLogin] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [userTemp, setUserTemp] = React.useState<IUserLogin>();
  const [openDetails, setOpenDetails] = React.useState(false);
  const [inputName, setInputName] = useState("default");
  const [customLayout, setCustomLayout] = useState<any>();

  const [layout, setLayout] = useState("default");

  const layoutNumeric = {
    default: [
      "1 2 3",
      "4 5 6",
      "7 8 9",
      " 0 ",
      "{bksp}"
    ]
  };
  const keyboardNome = useRef(null);



  const [inputNome, setInputNome] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputCelular, setInputCelular] = useState("");
  const [inputCEP, setInputCEP] = useState("");
  const [inputRua, setInputRua] = useState("");
  const [inputNumero, setInputNumero] = useState("");
  const [inputBairro, setInputBairro] = useState("");
  const [inputCidade, setInputCidade] = useState("");
  const [inputSenha, setInputSenha] = useState("");

  const changeFocus = useCallback((name) => {
    setInputName(name);
  }, []);

  useEffect(() => {

    if (inputName == "cep") {
      setCustomLayout(layoutNumeric);
    }
    if (inputName == "name") {
      setCustomLayout(undefined);
    }
    if (inputName == "email") {
      setCustomLayout(undefined);
    }
    if (inputName == "mobile") {
      setCustomLayout(layoutNumeric);
    }
    if (inputName == "birth") {
      //   setCustomLayout(layoutNumeric);
    }
    if (inputName == "address") {
      buscaCEP(inputCEP);
      setCustomLayout(undefined);
    }
    if (inputName == "neighborhood") {
      setCustomLayout(undefined);
    }
    if (inputName == "number") {
      setCustomLayout(layoutNumeric);
    }
    if (inputName == "city") {
      setCustomLayout(undefined);
    }
    if (inputName == "password") {
      setCustomLayout(undefined);
    }
  }, [inputName, inputCEP]);

  const onChangeInput = useCallback((input, name) => {

    console.log(input, name)
    if (name == "name") {
      setInputNome(input);
      setValue("name", input);
    }
    if (name == "email") {
      setInputEmail(input);
      setValue("email", input);
    }
    if (name == "birth") {
      // setInputNascimento(input);
      //setValue("birth", input);
    }
    if (name == "mobile") {

      setValue("mobile", input);

      setInputCelular(input);

    }
    if (name == "cep") {
      setInputCEP(input);
      setValue("cep", input);
    }
    if (name == "address") {
      setInputRua(input);
      setValue("address", input);
    }
    if (name == "neighborhood") {
      setInputBairro(input);
      setValue("neighborhood", input);
    }
    if (name == "number") {
      setInputNumero(input);
      setValue("number", input);
    }
    if (name == "password") {
      setInputSenha(input);
      setValue("password", input);
    }
    // if (name == "city") {
    //   setInputCidade(input);
    //   setInputCity(input)
    //   setValue("city", input);
    //   setOpenCity(true);
    // }
  }, []);

  const onChangeCidade = useCallback((input) => {
    setCidade(input);
    setValue("idCidade", input);
  }, []);

  const onKeyPress = useCallback((button) => {
    console.log(button)

    if (button === "{shift}" || button === "{lock}" || layout === "shift") {
      handleShift(layout);
    }

  }, [layout]);

  const handleShift = useCallback((layoutName: string) => {

    console.log(layoutName)
    setLayout(layoutName === "default" ? "shift" : "default")
  }, []);

  const setFocus = useEffect(() => {

    const planString = sessionStorage.getItem('@HubfitTotem:plan');
    const actionString = sessionStorage.getItem('@HubfitTotem:action');
    if (actionString == 'plan_payment' && planString) {
      const planParsed = JSON.parse(planString);
      if (planParsed) {
        setPlan(planParsed);
      }
    }

    setTimeout(() => {
      nameRef.current?.focus();
    }, 1000)
    if (history.location.state) {
      setCpfInput(history.location.state.cpf);
    }
  }, [history, setPlan])


  // useEffect(() => {
  //   function clickHanlder(e: any) {

  //     console.log(e.target.nodeName)

  //       console.log('entrou')
  //       if (!(e.target.nodeName === "INPUT") && !e.target.classList.contains("hg-button")) {
  //         setKeyboardVisibility(false);
  //       }

  //   }

  //   window.addEventListener("click", clickHanlder);
  //   return window.removeEventListener("click", clickHanlder, true);
  // }, []);



  const recaptchaLoaded = useCallback(() => {
    console.log('recaptcha load')
  }, [])

  const recaptchaCallback = useCallback((response) => {
    sessionStorage.setItem('@HubfitPortal:recap', response);
    console.log(response)
  }, [])

  function validadeCPF<bollean>(cpf: string | undefined | null) {
    console.log(cpf)
    if (cpf) {
      cpf = cpf.replace('.', '',).replace('.', '',).replace('-', '');
      //console.log(cpf)
      if (cpf == null) {
        return false;
      }
      if (cpf.length != 11) {
        return false;
      }
      if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222') || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555') || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888') || (cpf == '99999999999')) {
        return false;
      }
      let numero: number = 0;
      let caracter: string = '';
      let numeros: string = '0123456789';
      let j: number = 10;
      let somatorio: number = 0;
      let resto: number = 0;
      let digito1: number = 0;
      let digito2: number = 0;
      let cpfAux: string = '';
      cpfAux = cpf.substring(0, 9);
      for (let i: number = 0; i < 9; i++) {
        caracter = cpfAux.charAt(i);
        if (numeros.search(caracter) == -1) {
          return false;
        }
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
      }
      resto = somatorio % 11;
      digito1 = 11 - resto;
      if (digito1 > 9) {
        digito1 = 0;
      }
      j = 11;
      somatorio = 0;
      cpfAux = cpfAux + digito1;
      for (let i: number = 0; i < 10; i++) {
        caracter = cpfAux.charAt(i);
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
      }
      resto = somatorio % 11;
      digito2 = 11 - resto;
      if (digito2 > 9) {
        digito2 = 0;
      }
      cpfAux = cpfAux + digito2;
      if (cpf != cpfAux) {
        return false;
      }
      else {
        return true;
      }
    } else {
      return false;
    }
  }



  let RegisterPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Senha obrigatória'),
    // password_confirmation: Yup.string().oneOf(
    //   [Yup.ref('password'), undefined], 'Senha diferente a primeira',
    // )
  });
  function parseDateString(value: any, originalValue: string) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd/MM/yyyy", new Date());

    return parsedDate;
  }
  let RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Nome obrigatório'),
    email: Yup.string().required('E-mail obrigatório').email("E-mail inválido"),
    birth: Yup.date().transform(parseDateString).required('Data de nascimento obrigatória').typeError('Data inválida'),
    // .transform(parseDateString).max(today)
    gender: Yup.boolean().oneOf([true], "Escolha o gênero"),
    mobile: Yup.string(),
    cpf: Yup.string().test('cpf', 'CPF incorreto', (value) => (
      validadeCPF(value)
    )).required("CPF obrigatório"),
    address: Yup.string().required('Digite o endereço'),
    number: Yup.string().required('Digite o número'),
    neighborhood: Yup.string().required('Digite o bairro'),
    cep: Yup.string(),
    idCidade: Yup.string().required('Escolha a cidade'),

    // password: Yup.string().required('Senha obrigatória'),
    // password_confirmation: Yup.string().oneOf(
    //   [Yup.ref('password'), undefined],'Senha diferente a primeira',
    //)
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm<ISignUpFormData>({
    resolver: yupResolver(RegisterSchema),
  });

  const formPassword = useForm<SignUpLoginFormData>({
    resolver: yupResolver(RegisterPasswordSchema)
  });
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onChangeInput(date?.getDate(), "birth")
  };
  const handleTabReturn = useCallback(() => {
    setTab(0);
  }, []);
  const handleChangeGender = ((event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log((event.target as HTMLInputElement).value);
    setSelectedGender((event.target as HTMLInputElement).value);
  });
  const onSubmit = (data: ISignUpFormData) => {
    dataForms = data;
    if (cidade) {
      dataForms.idCidade = Number.parseInt(cidade.id);
    } else {
      throw new Error("Selecione uma cidade");
    }
    console.log(dataForms)
    setTab(1);
    setTimeout(() => {
      passwordRef.current?.focus();
    }, 1000)
  }


  const onSubmitPassword = useCallback(async (data: SignUpLoginFormData) => {
    try {
      dataForms.password = data.password;
      dataForms.idTenant = tenant.idTenant;
      dataForms.idUnidade = tenant.idBranch;
      dataForms.gender = selectedGender;
      dataForms.birth = dataForms.birth;
      dataForms.recaptcha = sessionStorage.getItem('@HubfitPortal:recap');
      if (userLocalized) {
        dataForms.idUserLocalized = userLocalized?.id;
        dataForms.typeUserLocalized = userLocalized?.type;
      }
      console.log(dataForms);
      setLoadingFinal(true);
      const response = await api.post('/users', dataForms);
      console.log(response.data);
      const usuario = response.data;
      const token = response.data.token;
      delete usuario.token;
      await updateUser(usuario, token);
      sessionStorage.setItem('@HubfitTotem:new_register', "true");
      history.push('/payments/checkout');
    } catch (err) {

      console.log(err)
      addToast({
        type: "error",
        title: 'Erro no cadastro',
        time: 20000,
        description: err && err.response && err.response.data ? (err.response.data.emailUsado == true ? 'O e-mail informado já está sendo utilizado. Escolha outro.' : (err.response.data.cpfInvalido == true ? 'O CPF informado é inválido' : 'Ocorreu um erro ao fazer cadastro, tente novamente.')) : 'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
      if (err.response != null) {
        if (err.response.data.emailUsado == true) {
          setTab(0);
        }
        if (err.response.data.cpfInvalido == true) {
          setTab(0);
          setCpfInput(undefined);
        }
      }
      setTab(0);
    } finally {
      setLoadingFinal(false);
    }

  }, [tenant, selectedGender, userLocalized]);

  const handleListItemUserLocalizedClick = useCallback((user: IUserLogin | undefined) => {
    if (user) {
      setUserLocalized(
        {
          id: user.id,
          type: user.tipoPessoa
        }
      );
      setOpenEmail(false);
    }
  }, [])

  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  const handleChangeEmail = useCallback(async (e) => {
    const val = e.target.value;
    console.log(val)
    if (val && validateEmail(val)) {
      const { data } = await api.get("/users/email", {
        params: {
          email: val,
          idTenant: tenant.idTenant,
        }
      });
      console.log(data)
      if (data.cadastro == 2) {
        //Já possui cadastro com login e senha, direcionar para tela de login
        setOpenModalLogin(true);
        addToast({
          type: "info",
          title: 'Cadastro encontrado',
          description: 'Encontramos um cadastro com o mesmo e-mail e com login e senha criados. Favor efetuar o login.',
          time: 10000
        });
      } else if (data.cadastro == 1) {
        //Cadastro com mesmo e-mail já encontrado
        setUserTemp(data);
        //  setOpenEmail(true);

      }
    } else {
      addToast({
        type: "error",
        title: 'E-mail inválido',
        description: 'Digite um e-mail válido',
        time: 5000
      });
    }
  }, [tenant.idTenant])


  const buscaCEP = useCallback((val) => {

    if (val) {
      if (val.replace('-', '').length == 8) {
        axios.get("//viacep.com.br/ws/" + val.replace('-', '') + "/json").then((response) => {
          console.log(response.data);
          setValue('address', response.data.logradouro)
          setInputRua(response.data.logradouro);
          setInputBairro(response.data.bairro);
          setValue('neighborhood', response.data.bairro);
          api.get('/cities', {
            params: {
              idTenant: tenant.idTenant,
              search: response.data.localidade,
            }
          }).then((response) => {
            if (response.data.length > 0) {
              setOptionsCity(response.data);
              setCidade(response.data[0]);


              console.log(response.data[0]);
            }
          })
        })
      }
    }
  }, []);

  const handleChangeCep = useCallback((e) => {
    const val = e.target.value;
    buscaCEP(val);

  }, [])

  React.useEffect(() => {
    if (inputCity) {
      setLoadingCity(true);
      console.log(inputCity)
      try {
        api.get('/cities', {
          params: {
            idTenant: tenant.idTenant,
            search: inputCity,
          }
        }).then((response) => {
          setOptionsCity(response.data);
          setLoadingCity(false);
        })
      } catch (err) {
        addToast({
          type: "error",
          title: 'Erro',
          time: 10000,
          description: 'Não foi possível buscar as informações de cidades.',
        });
      } finally {

      }
    }
  }, [inputCity]);
  const handleCloseEmail = useCallback(() => {
    setOpenEmail(false);
  }, []);
  const handleCloseModalLogin = useCallback(() => {
    setOpenModalLogin(false);
  }, []);

  const handleClickDetails = useCallback(() => {
    setOpenDetails(true);
  }, [])
  const handleCloseModalDetails = useCallback(() => {
    setOpenDetails(false);
  }, []);

  return (
    <>
      <Header isBack avatar={false} />
      <Container className={classes.containerMain}>
        <Grid container >
          <Grid item xs={12} sm={7} md={7} lg={8} >
            <AnimationContainer className={classes.animationTab}>
              <Background>

                <CarouselProvider
                  disableKeyboard
                  currentSlide={tab}
                  touchEnabled={false}
                  dragEnabled={false}
                  isIntrinsicHeight={true}
                  naturalSlideWidth={300}
                  naturalSlideHeight={200}
                  totalSlides={2}
                >
                  <Slider>
                    <Slide index={0} >
                      <form onSubmit={handleSubmit(onSubmit)} >
                        <Content>
                          <Grid container direction="row"
                            justify="center"
                            alignItems="center">
                            <Grid item xs={12} md={12} lg={8} >
                              <Typography variant="h5" gutterBottom>
                                Dados pessoais
                              </Typography>
                              <Paper elevation={1} className={classes.Paper} >
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth error={!!errors.cpf?.message}
                                      disabled={!!cpfInput}
                                    >
                                      <InputLabel htmlFor="cpf">CPF</InputLabel>
                                      <InputMask
                                        mask="999.999.999-99"
                                        disabled={false}
                                        maskChar=""
                                        value={cpfInput}
                                      >
                                         <Input name="cpf" id="cpf" inputRef={register} autoComplete="off" />
                                      </InputMask>
                                      <FormHelperText>{errors.cpf?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth error={!!errors.name?.message}>
                                      <InputLabel htmlFor="name">Nome</InputLabel>
                                      <Input onFocus={() => {

                                        changeFocus("name");
                                      }} value={inputNome} onChange={(e) => onChangeInput(e.target.value, "name")} name="name" id="name" inputRef={(e) => {
                                        register(e); nameRef.current = e
                                      }} autoComplete="off"
                                      />
                                      <FormHelperText>{errors.name?.message}</FormHelperText>

                                    </FormControl>

                                  </Grid>



                                  <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth error={!!errors.birth?.message}>
                                      <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >


                                        <KeyboardDatePicker error={!!errors.birth?.message}
                                          disableFuture
                                          openTo="year"
                                          views={["year", "month", "date"]}
                                          name='birth'
                                          id="birth"
                                          autoComplete="off"
                                          label="Nascimento"
                                          format="dd/MM/yyyy"
                                          inputRef={register}
                                          value={selectedDate}
                                          onChange={handleDateChange}
                                          onFocus={() => {
                                            changeFocus("birth");
                                          }}

                                          maxDateMessage="Data deve ser menor que hoje"
                                          cancelLabel="Cancelar"
                                          clearLabel="Limpar"
                                          invalidDateMessage=""
                                          minDateMessage="Verifique o ano"
                                        />
                                        <FormHelperText>{errors.birth?.message}</FormHelperText>

                                      </MuiPickersUtilsProvider>

                                    </FormControl>


                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <FormControl component="fieldset" error={!!errors.gender?.message}>
                                      <FormLabel component="legend">Gênero</FormLabel>
                                      <RadioGroup row aria-label="gender" name="gender" ref={register} value={selectedGender} onChange={handleChangeGender}>
                                        <FormControlLabel value="feminino" control={<Radio color="primary" />} label="Feminino" />
                                        <FormControlLabel value="masculino" control={<Radio color="primary" />} label="Masculino" />
                                      </RadioGroup>
                                      <FormHelperText>{errors.gender?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth error={!!errors.email?.message}>
                                      <InputLabel htmlFor="email">E-mail</InputLabel>
                                      <Input onFocus={() => {
                                        // setKeyboardVisibilityEmail(true);
                                        changeFocus("email");
                                      }} value={inputEmail} onChange={(e) => onChangeInput(e.target.value, "email")} name="email" id="email" type="email" inputRef={register} autoComplete="off" />
                                      <FormHelperText>{errors.email?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth error={!!errors.mobile?.message}>
                                      <InputLabel htmlFor="mobile">Celular</InputLabel>
                                      {/* <InputMask
                                        mask="(99) 99999-9999"
                                        maskChar=""
                                        onFocus={() => {
                                          //  setKeyboardVisibilityCelular(true);
                                          changeFocus("mobile");
                                        }}
                                        onChange={(e) => onChangeInput(e.target.value, "mobile")}
                                        value={inputCelular}
                                      >
                                        {() => */}
                                      <Input onFocus={() => {
                                        changeFocus("mobile");
                                      }}
                                        onChange={(e) => onChangeInput(e.target.value, "mobile")}
                                        value={inputCelular} name="mobile" id="mobile" type="text" inputRef={register} autoComplete="off" />
                                      {/* }
                                      </InputMask> */}

                                      <FormHelperText>{errors.mobile?.message}</FormHelperText>

                                    </FormControl>
                                  </Grid>

                                </Grid>
                              </Paper>

                              <Typography variant="h5" gutterBottom>
                                Endereço
                              </Typography>
                              <Paper elevation={1} className={classes.Paper} >
                                <Grid container spacing={3}>
                                  <Grid item xs={3}>
                                    <FormControl fullWidth error={!!errors.cep?.message}>
                                      <InputLabel htmlFor="cep">CEP</InputLabel>
                                      {/* <InputMask
                                        mask="99999-999"
                                        maskChar=""
                                        onBlur={handleChangeCep}
                                        onFocus={() => {
                                         
                                          setInputName("cep")
                                        }}
                                        onChange={(e) => onChangeInput(e.target.value, "cep")}
                                        value={inputCEP}
                                      >
                                        {() => */}
                                      <Input onBlur={handleChangeCep}
                                        onFocus={() => {

                                          changeFocus("cep");
                                        }}
                                        onChange={(e) => onChangeInput(e.target.value, "cep")}
                                        value={inputCEP} name="cep" id="cep" type='text' inputRef={register} autoComplete="off" />
                                      {/* }
                                      </InputMask>  */}
                                      <FormHelperText>{errors.cep?.message}</FormHelperText>
                                    </FormControl>


                                  </Grid>

                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={9}>

                                    <FormControl fullWidth error={!!errors.address?.message}>
                                      <InputLabel htmlFor="address" >Rua</InputLabel>
                                      <Input onFocus={() => {
                                        changeFocus("address");
                                      }} value={inputRua} onChange={(e) => onChangeInput(e.target.value, "address")}
                                        name="address" id="address" type="address" inputRef={register} autoComplete="off" />
                                      <FormHelperText>{errors.address?.message}</FormHelperText>

                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <FormControl fullWidth error={!!errors.number?.message}>
                                      <InputLabel htmlFor="number">Número</InputLabel>
                                      <Input onFocus={() => {
                                        changeFocus("number");
                                      }} value={inputNumero} onChange={(e) => onChangeInput(e.target.value, "number")} name="number" id="number" type="number" inputRef={register} autoComplete="off" />
                                      <FormHelperText>{errors.number?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <FormControl fullWidth error={!!errors.neighborhood?.message}>
                                      <InputLabel htmlFor="neighborhood" shrink={!!getValues('neighborhood')}>Bairro</InputLabel>
                                      <Input onFocus={() => {
                                        changeFocus("neighborhood");
                                      }} value={inputBairro} onChange={(e) => onChangeInput(e.target.value, "neighborhood")} name="neighborhood" id="neighborhood" type="text" inputRef={register} autoComplete="off" />
                                      <FormHelperText>{errors.neighborhood?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Autocomplete
                                      id="city"
                                      fullWidth
                                      disablePortal={true}
                                      open={openCity}
                                      onOpen={() => {
                                        setOpenCity(true);
                                      }}
                                      onClose={() => {
                                        setOpenCity(false);
                                      }}
                                      getOptionSelected={(option, value) => option.id === value.id}
                                      getOptionLabel={(option) => option.name}
                                      options={optionsCity}
                                      loading={loadingCity}
                                      noOptionsText="Nenhuma opção"
                                      value={cidade}
                                      inputValue={inputCidade}
                                      onInputChange={(event, newInputValue) => {
                                        setInputCidade(newInputValue);
                                        setInputCity(newInputValue);
                                      }}
                                      onChange={(event: any, newValue: ICity | null) => {

                                        onChangeCidade(newValue)
                                      }}
                                      onFocus={() => {
                                        changeFocus("city");

                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          error={!!errors.number?.message}
                                          {...params}
                                          label={"Cidade"}
                                          id="idCidade"
                                          name="idCidade"

                                          inputRef={register} autoComplete="off"
                                          helperText={errors.idCidade?.message}
                                          InputProps={{
                                            ...params.InputProps,

                                            endAdornment: (
                                              <React.Fragment>
                                                {loadingCity ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                              </React.Fragment>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Content>
                        <Content>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="flex-start"
                          >

                            <Button disabled={loading} type="submit" variant="contained" color="primary" size="large" endIcon={<ArrowForwardIos />}>
                              Continuar
                            </Button>


                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                          </Grid>
                        </Content>
                      </form>
                    </Slide>
                    <Slide index={1}>
                      <form onSubmit={formPassword.handleSubmit(onSubmitPassword)}>
                        <Content>

                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"

                          >

                            <Grid item xs={12} sm={12} md={8} lg={5} >
                              <Typography variant="h5" gutterBottom>
                                Crie uma senha
                              </Typography>


                              <Paper elevation={1} className={classes.Paper} >
                                <Grid container spacing={3}>
                                  <Grid item xs={12} >
                                    <FormControl fullWidth error={!!formPassword.errors.password?.message}>
                                      <InputLabel htmlFor="password">Nova senha</InputLabel>
                                      <Input name="password" id="password" type="password"
                                        inputRef={(e) => {
                                          formPassword.register(e); passwordRef.current = e
                                        }} autoComplete="off" onFocus={() => {
                                          changeFocus("password");
                                        }} value={inputSenha} onChange={(e) => onChangeInput(e.target.value, "password")} />
                                      <FormHelperText>{formPassword.errors.password?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                {/* <Grid container spacing={3}>
                                  <Grid item xs={12}>

                                    <FormControl fullWidth error={!!formPassword.errors.password_confirmation?.message}>
                                      <InputLabel htmlFor="password_confirmation">Confirmar senha</InputLabel>
                                      <Input name="password_confirmation" id="password_confirmation" type="password" inputRef={formPassword.register} autoComplete="off" />
                                      <FormHelperText>{formPassword.errors.password_confirmation?.message}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </Grid> */}
                                <div className={classes.divBack}>
                                  <Button onClick={handleTabReturn}>
                                    <ArrowLeft />
                                    Voltar
                                  </Button>
                                </div>
                              </Paper>
                            </Grid>
                          </Grid>

                        </Content>
                        <Content>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Recaptcha
                              ref={refRecaptcha}
                              hl='pt-BR'
                              size='normal'
                              sitekey="6LfHOKIUAAAAAPwaZ0c_LxLmhZV4wckS4xGKr9Nr"
                              render="explicit"
                              onloadCallback={recaptchaLoaded}
                              verifyCallback={recaptchaCallback}
                            />
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >

                            <Button disabled={loadingFinal} type="submit" variant="contained" color="primary" size="large" endIcon={<ArrowForwardIos />}>
                              Prosseguir
                              {loadingFinal && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>


                          </Grid>
                        </Content>
                      </form>
                    </Slide>
                  </Slider>
                </CarouselProvider>






              </Background>
            </AnimationContainer>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={4} >
            <Grid container direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={12} md={10} lg={8} className={classes.cardPadding}>
                {!plan &&
                  <img src={tenant.url_Logo} alt={tenant.name} className={classes.imageLogo} />
                }
                {plan &&
                  <Card>
                    <CardHeader >
                      <Typography variant="h4">{plan.nomePlano}</Typography>
                    </CardHeader>

                    <CardBody>
                      {plan.parcelas > 1 && !plan.dcc && (
                        <>
                          <Typography align="center">Em até {plan.parcelas}x de</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorTotal / plan.parcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                        </>
                      )}
                      {plan.parcelas == 1 && !plan.dcc && (
                        <>
                          <Typography align="center">à vista</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorTotal / plan.parcelas} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                        </>
                      )}
                      {plan.dcc && (
                        <>
                          <Typography align="center">Mensal.</Typography>
                          <Typography variant="h3" align="center" className={classes.valuePlan}><NumberFormat value={plan.valorDcc} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Typography>
                        </>
                      )}

                    </CardBody>
                    <Divider variant="fullWidth" />
                    <CardBody>
                      <GridFooterCard>
                        <Grid container
                          direction="row"
                          justify="space-between"
                          alignItems="center">
                          <Grid><p>Adesão:</p></Grid>
                          <Grid><NumberFormat value={plan.adesaoValor} displayType={'text'} decimalSeparator="," thousandSeparator="." decimalScale={2} fixedDecimalScale={true} prefix={'R$'} /></Grid>

                        </Grid>

                      </GridFooterCard>
                    </CardBody>
                    <CardFooter>
                      <Grid

                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} alignContent="center">
                          <Button
                            fullWidth
                            color="secondary"
                            onClick={handleClickDetails}
                          >
                            Mais detalhes
                          </Button>
                        </Grid>
                      </Grid>
                    </CardFooter>
                  </Card>
                }

              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog PaperProps={{ className: classes.overflowCard }} maxWidth="xs" fullWidth onClose={handleCloseModalLogin} aria-describedby="form-dialog-title" open={openModalLogin}>

        <SignInModal />

      </Dialog>
      <Dialog onClose={handleCloseEmail} open={openEmail}>
        <DialogTitle id="simple-dialog-title">Cadastro encontrado.</DialogTitle>
        <DialogContentText className={classes.marginContent}>
          Encontramos outro cadastro com seu e-mail. Clique abaixo para selecionar a conta correta.
        </DialogContentText>
        <List>
          <ListItem button
            onClick={() => handleListItemUserLocalizedClick(userTemp)}
            key={userTemp?.id}>
            <ListItemAvatar>
              <Avatar src={userTemp?.avatarUrl} >
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={userTemp?.nome} secondary={userTemp?.email} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Selecionar">
                <Check />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {userTemp?.dependentes.map((user) => (
            <ListItem button
              onClick={() => handleListItemUserLocalizedClick(user)}
              key={user.id}>
              <ListItemAvatar>
                <Avatar src={user?.avatarUrl}>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={userTemp?.nome} secondary={userTemp?.email} />

            </ListItem>
          ))}
        </List>
      </Dialog>
      <DialogPlanDetails onClose={handleCloseModalDetails} open={openDetails} plan={plan} />

      {tenant.virtual_key &&
        <Drawer
          anchor={"bottom"}
          variant="permanent"
          open={true}
          // className={classes.drawerKey}
          classes={{
            paper: classes.drawerKey
          }}

        >
          <Keyboard

            inputName={inputName}
            keyboardRef={r => (keyboardNome.current = r)}
            layoutName={layout}
            layout={customLayout}
            theme={"hg-theme-default myTheme1"}
            onChange={(e) => onChangeInput(e, inputName)}
            onKeyPress={onKeyPress}
            mergeDisplay={true}
            display={{
              '{bksp}': '&#8592',
            }}
          />

        </Drawer>
      }

    </>
  )
};
export default SignUp;

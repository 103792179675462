import React, { useRef, useCallback, useEffect } from 'react';
import {
  Input, Button, CircularProgress, Grid, Dialog, Typography, IconButton,
  DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions} from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme, WithStyles, withStyles } from '@material-ui/core/styles';
import {  RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import { green, blue } from '@material-ui/core/colors';


interface FindCPFFormData {
  cpf: string;
}

interface SignInFormData {
  password: string;
}
interface SignUpFormData {
  password: string;
  password_confirmation: string;
  email: string;
}
interface IUserLogin {
  avatarUrl: string;
  email: string;
  nome: string;
  id: number;
  tipoPessoa: number;
  cadastroProspect: boolean;
  cadastro: number;
}
enum TipoPessoa {
  Colaborador = 0,
  Aluno = 1,
  Personal = 2,
  Prospect = 3,
  Banco = 4,
  Filial = 5,
  Empresa = 6
}
enum ChecarCadastroCPF {
  SemCadastro = 0,
  PossuiCadastro = 1,
  PossuiLogin = 2,
  PossuiFacebook = 3
}
interface IFormCreateLogin {
  id: number;
  email: string;
  senha: string;
  tipoPessoa: number;
  idTenant: string;
}
var dataFormsCpf: FindCPFFormData;
var dataFormsCreateLogin: IFormCreateLogin;
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme: Theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: '60px 0 0',
    width: '100%',
    position: 'relative',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  SpanCard: {
    color: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',

  },
  IconInfo: {
    color: blue[500],
  },
  RingOff: {
    'outline-style': 'inherit',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleSignup: {
    margin: '10px 0',
  },
  rootAvatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  descUnidade: {
    color: theme.palette.text.hint,
  }


}));


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

export enum SegundoPasso {
  cadastroSenha,
  loginSenha,
}


const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

type RouteParams = {
  id?: string
}
type BookDetailProps = RouteComponentProps<RouteParams>;

const SignOut = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { signIn, tenant, updateUser, updateTenant } = useAuth();
  const cpfRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [userTemp, setUserTemp] = React.useState<IUserLogin>();
  const [segundoPasso, setSegundoPasso] = React.useState<SegundoPasso>(SegundoPasso.loginSenha);
  const [cpfLogin, setCpfLogin] = React.useState("");

  const { addToast } = useToast();
  const history = useHistory();

  const cpfSignupRef = useRef<HTMLInputElement | null>(null);
  const emailSignupRef = useRef<HTMLInputElement | null>(null);
  const [unidade, setUnidade] = React.useState<any>();

  useEffect(() => {
    localStorage.removeItem('@HubfitTotem:tenant');
    window.location.reload();
  }, [])




  return (
    <div>
     
    </div>

  )
};
export default SignOut;

import React, { useCallback, useState, useEffect } from 'react';
import { format, setDay, isBefore, addDays } from 'date-fns';
import classNames from "classnames";

import { ContentModal } from './styles';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Typography, Button, Grid, Divider,
  DialogContent as MuiDialogContent, Dialog, CircularProgress, CardMedia, CardContent, Card, Chip, Paper, Avatar
} from "@material-ui/core";
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import { Content } from "./styles";
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import Header from '../../../components/Header';
import Timer from '../../../components/TimerCountdown';
import { red, green } from '@material-ui/core/colors';
import { useAuth } from '../../../hooks/auth';
import { IClassItem, IVerify, IReturnVerify } from '../../../models/Classes/IClassesDTO';
import { ArrowLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    rootLoading: {
      display: 'flex',
      cursor: 'default',
      opacity: '0.8',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    vagas: {
      borderRadius: '23px',
      height: '48px',
      fontSize: '32px',

    },
    divVagas: {
      display: 'flex',
      alignItems: 'center',
      width: '138px',
      flexDirection: 'column',
      alignSelf: 'center',
      // marginTop: '-16px',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 200,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    titleClass: {
      margin: '2px 5px 8px 14px !important',
    },
    timeClass: {
      margin: '0 !important',
      color: theme.palette.text.secondary,
    },
    timeRest: {
      color: theme.palette.text.disabled,
      marginLeft: '10px',
      fontSize: '14px',
    },
    divFlexTime: {
      display: 'inline-flex',
      alignItems: 'flex-end'

    },
    textVagas: {
      color: theme.palette.text.secondary,
    },
    textConfirmado: {
      background: green[500],
      height: '32px',
      fontSize: '19px',
      borderRadius: '23px',
    },
    coverModal: {
      width: 'auto',
      height: '185px',
      backgroundColor: '#e4e4e4',
    },
    titleLigh: {
      color: theme.palette.text.disabled,
    },
    titleCoverModal: {
      position: 'absolute',
      top: '25%',
      left: '50%',
      color: 'white',
      transform: 'translate(-50%, -50%)',
      textShadow: '1px 2px 4px #000000',
    },
    contentModal: {
      position: 'relative',
    },
    btnRegister: {
      position: 'absolute',
      right: '15px',
      top: '-18px',
    },
    cardText: {
      textAlign: 'center',
    },
    contentDialog: {
      marginTop: '10px',
    },
    section2: {
      margin: theme.spacing(2),
    },
    buttonRed: {
      color: theme.palette.getContrastText(red[500]),
      background: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    disableRegister: {

      color: 'rgb(0 0 0 / 55%)',
      backgroundColor: 'rgb(189 189 189 / 84%)',
      '&:hover': {
        backgroundColor: 'rgb(189 189 189 / 84%)',
      },
      cursor: 'inherit',
    },
    noClasses: {
      textAlign: 'center',
      padding: '60px',
      opacity: '0.7',
      '& h5': {
        color: theme.palette.text.secondary,
      }
    }
  }),
);





const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);



const List= () => {
  const classes = useStyles();
  const [listAulas, setListAulas] = useState<IClassItem[]>();
  const [openModal, setOpenModal] = React.useState(false);
  const { tenant } = useAuth();
  const [aulaModal, setAulaModal] = React.useState<IClassItem | undefined>();
  const [verificacao, setVerificacao] = React.useState<IReturnVerify | undefined>();
  const [verificando, setVerificando] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingClasses, setLoadingClasses] = React.useState(true);
  const { addToast } = useToast();
  const history = useHistory();

  const getClasses = useCallback(() => {

    const classString = sessionStorage.getItem('@HubfitTotem:class');
    const actionString = sessionStorage.getItem('@HubfitTotem:action');
    if (classString && actionString == "class_register") {
      const aulaSelected = JSON.parse(classString);
      setAulaModal(aulaSelected);
      setOpenModal(true);
      try {
        api.get('/checkin/verify', {
          params: {
            idAula: aulaSelected.IdAula,
            horaInicio: aulaSelected.HorarioInicio,
            idAulaGrade: aulaSelected.IdGrade,
            data: getDataSemana(aulaSelected.DiaSemana)
          }
        }).then((response) => {
          setVerificacao(response.data);

        });
      } catch (err) {
        //TODO Mensagem de erro
      } finally {
      }
    }
    sessionStorage.removeItem('@HubfitTotem:action');
    sessionStorage.removeItem('@HubfitTotem:class');

    api.get('/classes/totem', {
      params: {
        idTenant: tenant.idTenant,
        idTotem: tenant.idTotem,
      }
    }).then(response => {
      console.log(response.data)
      setLoadingClasses(false);
      setListAulas(response.data);
    })
  }, []);

  useEffect(() => {
    getClasses();
  }, []);


  const handleClickReturn = useCallback(() => {
    history.push("/dashboard");
  }, [history]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setLoading(false);
    setVerificacao(undefined);

  }, []);


  const handleClickAula = useCallback((id: number) => {
    const aulaSelected = listAulas?.find((e) => e.IdGrade == id);
    setVerificando(true);
    if (aulaSelected) {
      setOpenModal(true);
      setAulaModal(aulaSelected);
      try {
        api.get('/checkin/verify', {
          params: {
            idAula: aulaSelected.IdAula,
            horaInicio: aulaSelected.HorarioInicio,
            idAulaGrade: aulaSelected.IdGrade,
            data: getDataSemana(aulaSelected.DiaSemana)
          }
        }).then((response) => {

          setVerificacao(response.data);
          setVerificando(false);
        });
      } catch (err) {
        //TODO Mensagem de erro
      } finally {

      }
    }
  }, [listAulas]);

  function getDataSemana(diaSemana: number): string {
    const dateAula = new Date();
    var dateAulaSemana = setDay(dateAula, diaSemana - 1)

    if (isBefore(new Date, dateAulaSemana)) {
      dateAulaSemana = addDays(dateAulaSemana, 7);
    }
    return format(dateAulaSemana, 'yyyy-MM-dd');
  }

  function parseTime(t: string) {

    var d = new Date();

    var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    if (time) {
      d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
      d.setMinutes(parseInt(time[2]) || 0);
    }
    return d;
  }

  const handleAulaParticipar = useCallback(async () => {
    setLoading(true);

    try {
      if (aulaModal) {
        const register: IVerify = {
          idAula: aulaModal.IdAula,
          horaInicio: aulaModal.HorarioInicio,
          idAulaGrade: aulaModal.IdGrade,
          data: getDataSemana(aulaModal.DiaSemana)
        }

        const response = await api.post('/checkin/confirm', register);
        const { Retorno, Mensagem } = response.data;
        if (Retorno == 2) {
          addToast({
            type: "success",
            title: "Presença registrada com sucesso",
          });
          getClasses();
        } else {
          addToast({
            type: "error",
            title: Mensagem,
          });
        }

      }
    } catch (err) {

      addToast({
        type: "error",
        title: 'Erro no registro',
        description: 'Não foi possível registrar a presença',
      });
    } finally {
      setOpenModal(false);
      setLoading(false);
      setAulaModal(undefined);
      setTimeout(() => {
        setVerificacao(undefined);
      }, 1000);
    }
  }, [aulaModal]);

  const handleAulaRemover = useCallback(async () => {
    setLoading(true);

    try {
      if (aulaModal && verificacao) {

        const response = await api.delete('/checkin/cancel', {
          params: {
            IdHistoricoAula: verificacao.IdHistoricoAula,
            IdAulaGrade: aulaModal.IdGrade,
          }
        });
        getClasses();
        addToast({
          type: "success",
          title: "Presença cancelada com sucesso",
        });
      }
    } catch (err) {

      addToast({
        type: "error",
        title: 'Erro no registro',
        description: 'Não foi possível remover a presença',
      });
    } finally {
      setOpenModal(false);
      setLoading(false);
      setAulaModal(undefined);
      setTimeout(() => {
        setVerificacao(undefined);
      }, 1000);
    }
  }, [aulaModal, verificacao]);

  return (
    <>
      <Header linkPush="/dashboard" avatar />
      <div>
        <div>
          <Content>
            <Grid container spacing={3} direction="row"
              justify="center"
              alignItems="center" >

              {loadingClasses &&
                <>
                  <Grid item xs={12} sm={12} md={10} lg={8}  >
                    <Card className={classes.rootLoading}>
                      <Skeleton variant="rect" width={210} height={118} />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <h2 className={classes.titleClass}>
                            <Skeleton animation="wave" variant="rect" />
                          </h2>
                          <div className={classes.divFlexTime}>
                            <h1 className={classes.timeClass}>
                              <Skeleton animation="wave" variant="rect" />
                            </h1>
                          </div>
                        </CardContent>
                        <div className={classes.divVagas}>
                          <Skeleton animation="wave" variant="rect" />
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10} lg={8}  >
                    <Card className={classes.rootLoading}>
                      <Skeleton variant="rect" width={210} height={118} />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <h2 className={classes.titleClass}>
                            <Skeleton animation="wave" variant="rect" />
                          </h2>
                          <div className={classes.divFlexTime}>
                            <h1 className={classes.timeClass}>
                              <Skeleton animation="wave" variant="rect" />
                            </h1>
                          </div>
                        </CardContent>
                        <div className={classes.divVagas}>
                          <Skeleton animation="wave" variant="rect" />
                        </div>
                      </div>
                    </Card>
                  </Grid>
                </>
              }
              {(!listAulas || listAulas.length == 0) && !loadingClasses &&
                <Grid item xs={12}  >
                  <Paper elevation={3} className={classes.noClasses}>
                    <Typography align="center" variant="h5" component="h5" >Nenhum horário disponível</Typography>
                    <Button onClick={handleClickReturn}>
                      <ArrowLeft />
                    Voltar
                </Button>
                  </Paper>
                </Grid>
              }
              {listAulas?.map(aula => (
                <Grid item xs={12} sm={12} md={10} lg={8} key={aula.IdGrade}>
                  <Card className={classes.root} onClick={() => handleClickAula(aula.IdGrade)}>
                    <CardMedia
                      className={classes.cover}
                      image={aula.FotoAula}
                      title={aula.NomeAula}
                    />
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <h2 className={classes.titleClass}>
                          {aula.NomeAula}
                        </h2>
                        <div className={classes.divFlexTime}>
                          <h1 className={classes.timeClass}>
                            <Chip className={classes.vagas} label={aula.HorarioInicio.replace(':00', '')} />
                          </h1>
                          <Timer className={classes.timeRest} start={parseTime(aula.HorarioInicio)} />
                        </div>

                      </CardContent>
                      <div className={classes.divVagas}>
                        {aula.PresencaConfirmada &&
                          <Chip color="primary" className={classes.textConfirmado} label="Confirmado" />
                        }
                        {!aula.PresencaConfirmada &&
                          <>
                            <Typography variant="body2" className={classes.textVagas}>Vagas</Typography>
                            <Chip color="primary" className={classes.vagas} label={`${aula.Presencas.length} / ${aula.Capacidade}`} />
                          </>
                        }
                      </div>
                    </div>

                  </Card>
                </Grid>
              )
              )}
            </Grid>
          </Content>
        </div>
      </div>

      <Dialog maxWidth="md" onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>

        <CardMedia
          className={classes.coverModal}
          image={aulaModal?.FotoAula}
          title={aulaModal?.NomeAula}
        />
        <Typography component="h5" variant="h5" className={classes.titleCoverModal}>{aulaModal?.NomeAula}</Typography>

        <ContentModal className={classes.contentModal}>
          {tenant.permissions.classesCheckin &&
            <>
              {verificando &&
                <Button disabled={true} variant="contained" className={classNames(classes.btnRegister, classes.buttonRed)} >
                  Verificando
              <CircularProgress size={24} className={classes.buttonProgress} />
                </Button>
              }
              {verificacao && (verificacao?.Retorno == 13 || verificacao?.Retorno == 12) &&
                <Button disabled={loading} variant="contained" color="primary" className={classes.btnRegister} onClick={handleAulaParticipar}>
                  Participar
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              }
              {verificacao && (verificacao?.Retorno == 1 || verificacao?.Retorno == 4) &&
                <Button disabled={loading} variant="contained" className={classNames(classes.btnRegister, classes.buttonRed)} onClick={handleAulaRemover}>
                  Remover presença
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>

              }
              {verificacao && verificacao?.Retorno != 1 && verificacao?.Retorno != 4 && verificacao?.Retorno != 13 && verificacao?.Retorno != 12 &&
                <Button variant="contained" color="primary" className={classNames(classes.btnRegister, classes.buttonRed)}  >
                  {verificacao?.Mensagem}
                </Button>
              }
            </>
          }
          <DialogContent className={classes.contentDialog} >
            <div className={classes.section2}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}  >
                  <Card>
                    <CardContent className={classes.cardText}>
                      <Typography gutterBottom variant="body2" component="p">
                        Data
                </Typography>
                      <Typography variant="h6" color="textPrimary" component="p">{aulaModal?.Data}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}  >
                  <Card>
                    <CardContent className={classes.cardText}>
                      <Typography gutterBottom variant="body2" component="p">
                        Início
                </Typography>
                      <Typography variant="h6" color="textPrimary" component="p">{aulaModal?.HorarioInicio.replace(':00', '')}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}  >
                  <Card>
                    <CardContent className={classes.cardText}>
                      <Typography gutterBottom variant="body2" component="p">
                        Vagas
                </Typography>
                      <Typography variant="h6" color="textPrimary" component="p">{aulaModal?.Presencas.length}/{aulaModal?.Capacidade}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* <Grid item xs={6} sm={3}  >
                  <Card>
                    <CardContent className={classes.cardText}>
                      <Typography gutterBottom variant="body2" component="p">
                        Sala
                     </Typography>
                      <Typography variant="h6" color="textPrimary" component="p">{aulaModal?.Sala}</Typography>
                    </CardContent>
                  </Card>
                </Grid> */}
              </Grid>
            </div>
            <Divider variant="middle" />
            <div className={classes.section2}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography component="p" variant="body2" className={classes.titleLigh}>Pessoas confirmadas</Typography>
                </Grid>
                <AvatarGroup max={10}>
                  {aulaModal?.Presencas.map(item => (
                    <Avatar alt={item.Nome} src={item.AvatarUrl} />
                  ))}


                </AvatarGroup>
              </Grid>
            </div>
          </DialogContent>
        </ContentModal>

      </Dialog>
    </>
  )

}



export default List;
